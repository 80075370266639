import React from "react";
import { Empty } from "antd";

import EMPTY_IMG from "assets/images/empty.png";

interface IProps {
    message?: string;
}

const DEFAULT_DES = "No data found. Please try again.";

const EmptyData = ({message = DEFAULT_DES}: IProps) => {
	return (
		<Empty
            className="empty-section"
			image={EMPTY_IMG}
			description={message}
		/>
	);
};

export default EmptyData;
