import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout as AntdLayout, Menu, Button } from "antd";
import type { MenuProps } from "antd";

import { UseAppDispatch, UseAppSelector } from "hooks/hooks";
import { authLogout } from "store/slices/authSlice";
import {
	Box,
	Container,
	FlexBox,
	Paragraph,
} from "components/StyledComponents";

import {
	FaFacebookF,
	FaYoutube,
	FaInstagram,
	FaPinterestP,
} from "react-icons/fa";
import { logoutUser } from "services/auth";

import LOGO from "assets/images/logo.png";
import LOGO_WHITE from "assets/images/logo-white.png";

import "components/layouts/style.scss";
import { persistStore } from "redux-persist";
import store from "store/store";
import MapFooter from "./MapFooter";
import MainFooter from "./MainFooter";
import { getSocialData } from "services/pages";

const { Header, Footer } = AntdLayout;

const Layout: React.FC = () => {
	const dispatch = UseAppDispatch();
	const token = UseAppSelector((state) => state.auth.token);

	const location = useLocation();
	const navigate = useNavigate();
	const isHome = location.pathname === "/" ? true : false;
	// const mapPages = ["mark-locations"]
	const isMapPage = location.pathname.split("/")[1] === "mark-location" ? true : false;
	// console.log(location.pathname.split("/")[1]);

	const [socialLinks, setSocialLinks] = useState<any>(null);

	useEffect(() => {
		fetchSocialsData()
	}, []);
	

	const persistor = persistStore(store);

	const handleLogout = () => {
		persistor.flush().then(() => {
			return persistor.purge();
		});
		userLogout();
	};

	const menuItems: MenuProps["items"] = [
		// { label: <Link to={`/`}>Home</Link>, key: 'home' }
	];

	if (!token) {
		menuItems.push({
			label: <Link to={`/login`}>Sign In</Link>,
			key: "login",
		});
		// menuItems.push({ label: <Link to={`/signup`}>Sign Up</Link>, key: 'signup' });
	} else {
		menuItems.push({
			label: <Link to={`/mark-location`}>Mark Location</Link>,
			key: "mark-location",
		});
		menuItems.push({
			label: "Logout",
			key: "login",
			onClick: handleLogout,
		});
	}

	// API
	const userLogout = async () => {
		try {
			await logoutUser();
			dispatch(authLogout());
		} catch (error) {
			console.log(error);
		} finally {
			console.log("User logged");
		}
	};

	const fetchSocialsData = async () => {
		try {
			const response: any = await getSocialData();
			setSocialLinks(response.data.footer_section);
			console.log("response >>>>>>>>>>..", response);
			
		} catch (error) {
			console.log(error);
		} finally {
			console.log("User logged");
		}
	};

	return (
		<>
			<AntdLayout className="layout">
				<Header className={`header ${!isHome && "header-bg"}`}>
					<Container>
						<Box className="logo">
							<Link to="/">
								<img
									src={isHome ? LOGO : LOGO_WHITE}
									alt="Logo"
								/>
							</Link>
						</Box>
						<Box className="menu-box">
							<Menu
								className="header-menu"
								mode="horizontal"
								defaultSelectedKeys={["2"]}
								items={menuItems}
							/>
							{!token && (
								<Button
									type="primary"
									className={`create-account-btn ${
										!isHome && "btn-white"
									}`}
									onClick={() => navigate("/signup")}
								>
									Create Account
								</Button>
							)}
						</Box>
					</Container>
				</Header>

				<Outlet />

				<Footer className="site-footer">
					{isMapPage ? <MapFooter /> : <MainFooter socialLinks={socialLinks} />}
					<Box className="footer-bottom">
						<Container>
							<FlexBox
								flexDirection="row"
								justifyContent="space-between"
							>
								<Paragraph fontSize={14}>
									@ 2023 - All right reserved
								</Paragraph>
								<Paragraph fontSize={14}>
									Made By <a href="https://www.debuggersolutions.com/" target="_blank" rel="noreferrer">Debugger Solutions</a>
								</Paragraph>
								{socialLinks && 
								<FlexBox flexDirection="row" gap={12}>
									<a href={socialLinks.facebook} target="_blank" rel="noreferrer">
										<FaFacebookF />
									</a>
									<a href={socialLinks.instagram} target="_blank" rel="noreferrer">
										<FaYoutube />
									</a>
									<a href={socialLinks.pinterest} target="_blank" rel="noreferrer">
										<FaInstagram />
									</a>
									<a href={socialLinks.youtube} target="_blank" rel="noreferrer">
										<FaPinterestP />
									</a>
								</FlexBox>}
							</FlexBox>
						</Container>
					</Box>
				</Footer>
			</AntdLayout>
		</>
	);
};

export default Layout;
