import React from 'react'
import { UseAppSelector } from '../../hooks/hooks'
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedLayout: React.FC = () => {

    const token = UseAppSelector(state => state.auth.token);

    if(!token){
        return <Navigate to="/login" />
    }

    return (
        <Outlet />
    )
}

export default ProtectedLayout