import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Input, Tabs, Tour, TourProps } from "antd";
import type { TabsProps } from "antd";

import Trips from "./tabs/Trips";
import Scenes from "./tabs/Scenes";
// import SceneDetail from "./SceneDetail";
import MapPageLayout from "./MapPageLayout";

import "pages/map/style.scss";
import { Box } from "components/StyledComponents";
import { UseAppDispatch, UseAppSelector } from "hooks/hooks";
import { updateTourStatusAuth } from "store/slices/authSlice";
import { updateTourStatus } from "services/auth";
import SceneDetail from "./SceneDetail";

const MapMarker: React.FC = () => {
	const dispatch = UseAppDispatch();

	const ref1 = useRef(null);

	// const history = useHistory();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isTripScenesOpen, setIsTripScenesOpen] = useState<boolean | null>(
		null
	);
	const [isSceneDetailOpen, setIsSceneDetailOpen] = useState<boolean | null>(
		null
	);
	const [currentTrip, setCurrentTrip] = useState<string>("");
	const [currentScene, setCurrentScene] = useState<string>("");
	const [open, setOpen] = useState<boolean>(false);

	const isCollapse = UseAppSelector((state) => state.map.isCollapse);
	const user = UseAppSelector((state) => state.auth.user);

	useEffect(() => {
		setTimeout(() => {
			user?.tourStatus === 2 && isCollapse && setOpen(true);
		}, 1000);
	}, [user, isCollapse]);

	// MAP

	useEffect(() => {
		console.log("searchParams >>", searchParams);
		const tripId = searchParams.get("trip_id");
		const sceneId = searchParams.get("scene_id");

		if(tripId){
			searchParams.set("trip_id", tripId);
			setSearchParams(searchParams);
			setCurrentTrip(tripId);
			setIsSceneDetailOpen(false);
			setIsTripScenesOpen(true);
		} else if(sceneId){
			searchParams.delete("trip_id");
			searchParams.set("scene_id", sceneId);
			setSearchParams(searchParams);
			setCurrentScene(sceneId);
			setIsSceneDetailOpen(true);
		}else{
			setIsTripScenesOpen(false);
			setIsSceneDetailOpen(false);
		}
	}, [searchParams])

	useEffect(() => {
		const tripId = searchParams.get("trip_id");
		const sceneId = searchParams.get("scene_id");
		tripId && setCurrentTrip(tripId);
		sceneId && setCurrentScene(sceneId);
		setIsTripScenesOpen(tripId ? true : false);
		setIsSceneDetailOpen(sceneId ? true : false);
	}, []);

	const handleTripClick = (tripId: string) => {
		searchParams.set("trip_id", tripId);
		setSearchParams(searchParams);
		setCurrentTrip(tripId);
		setIsTripScenesOpen(true);
	};

	const handleSceneClick = (sceneId: string) => {
		searchParams.delete("trip_id");
		searchParams.set("scene_id", sceneId);
		setSearchParams(searchParams);
		setCurrentScene(sceneId);
		setIsSceneDetailOpen(true);
	};

	const handleSceneBreacrumbClick = () => {
		searchParams.delete("trip_id");
		setSearchParams(searchParams);
		setIsTripScenesOpen(false);
	};

	const steps: TourProps["steps"] = [
		{
			title: "Tabs",
			description:
				"Click on tab to switch between trips and scenes with no trips",
			// target: () => ref2.current,
			placement: "right",
			target: document.getElementsByClassName(
				"ant-tabs-nav-wrap"
			)[0] as HTMLElement,
		},
		{
			title: "Trips",
			description: "Click this trip to see all of the scenes",
			// target: () => ref1.current,
			placement: "right",
			target: document.getElementsByClassName(
				"trip-box-single"
			)[0] as HTMLElement,
		},
	];

	const handleClose = () => {
		setOpen(false);
		updateStatusTour();
	};

	// Tab Items
	const items: TabsProps["items"] = [
		{
			key: "trips",
			label: `TRIPS`,
			children:
				isTripScenesOpen !== null &&
				(isTripScenesOpen ? (
					<Scenes
						handleSceneBreacrumbClick={handleSceneBreacrumbClick}
						tripId={currentTrip}
						handleSceneClick={handleSceneClick}
					/>
				) : (
					<Box>
						<Trips ref={ref1} handleTripClick={handleTripClick} />
					</Box>
				)),
		},
		{
			key: "no-trips",
			label: `SCENES WITH NO TRIP`,
			children: <Scenes handleSceneClick={handleSceneClick} />,
		},
	];

	const handleTabsChange = (key: string) => {
		searchParams.set("tab", key);
		searchParams.delete("trip_id");
		setSearchParams(searchParams);
		setIsTripScenesOpen(false);
	};

	// API CALL

	const updateStatusTour = async () => {
		try {
			await updateTourStatus({
				tour_status: (user?.tourStatus || 2) + 1,
			});
			dispatch(updateTourStatusAuth((user?.tourStatus || 2) + 1));
		} catch (error) {
			console.log(error);
		} finally {
			console.log("finished");
		}
	};

	return (
		<>
			<MapPageLayout zoom={isSceneDetailOpen ? 14 : 4}>
				{isSceneDetailOpen ? (
					<SceneDetail id={currentScene} />
				) : (
					<Tabs
						onChange={handleTabsChange}
						className="tab-btns"
						defaultActiveKey={searchParams.get("tab") || "trips"}
						items={items}
						destroyInactiveTabPane
					/>
				)}
			</MapPageLayout>
			<Tour open={open} onClose={handleClose} steps={steps} />
		</>
	);
};

export default MapMarker;
