import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Breadcrumb, Button, Image, Empty } from "antd";
import { EnvironmentOutlined, DeleteOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";

import { Box, FlexBox, Paragraph } from "components/StyledComponents";

import MapPageLayout from "./MapPageLayout";
import {
	deleteGallery,
	deleteTour,
	getTourDetail,
	updateScene,
} from "services/map";
import SceneDetailSkeleton from "components/skeleton/SceneDetailSkeleton";
import { UseAppDispatch } from "hooks/hooks";
import { addScenesData, updateRenderKey } from "store/slices/mapSlice";
import { ISceneDetail } from "types";
import EditSceneDrawer from "components/drawers/EditSceneDrawer";
import ConfirmModal from "components/modals/ConfirmModal";
import { notifications } from "components/notifications";
import { DEFAULT_ERROR_MSG } from "constants/index";

interface IBreadCrumb {
	title: string | JSX.Element;
	key: string;
}

interface IProps {
	id: string;
}

const SceneDetail = ({ id }: IProps) => {
	// const { id } = useParams();
	const dispatch = UseAppDispatch();

	const navigation = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams();
console.log("location.pathname >", location.pathname);

	const [sceneDetail, setSceneDetail] = useState<ISceneDetail | null>(null);

	const filterQueryStrings = () => {
		const filterReqData = [
			searchParams.get("city") ? `city=${searchParams.get("city")}` : "",
			searchParams.get("trip") ? `trip=${searchParams.get("trip")}` : "",
			searchParams.get("type") ? `type=${searchParams.get("type")}` : "",
			searchParams.get("type") ? `type=${searchParams.get("type")}` : "",
		];
		return filterReqData.filter((data: string, index: number)=> data && data).join("&");
	}

	const filterQueryString = `/mark-location/filter?${filterQueryStrings()}`

	const filterBreadCrumb: IBreadCrumb[] = [
		{ title: <Link to="/mark-location">Trips</Link>, key: "trips" },
		{
			title: (
				<Link
					to={filterQueryString}
				>
					Filter
				</Link>
			),
			key: "trip",
		},
		{ title: "Scenes", key: "Scenes" },
	];


	const brdeacrumbItems: IBreadCrumb[] = location.pathname === "/mark-location/filter" ? filterBreadCrumb : [
		{ title: <Link to="/mark-location">Trips</Link>, key: "trips" },
		{
			title: (
				<Link
					to={`/mark-location?${
						sceneDetail?.tripId
							? `trip_id=${sceneDetail?.tripId}`
							: `tab=no-trip`
					}`}
				>
					Trip
				</Link>
			),
			key: "trip",
		},
		{ title: "Scenes", key: "Scenes" },
	];

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [isUploading, setIsUploading] = useState<boolean>(false);

	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const [visible, setVisible] = useState<boolean>(false);
	const [confirmSceneVisible, setConfirmSceneVisible] =
		useState<boolean>(false);

	const [activeImageKey, setActiveImageKey] = useState<number>(-1);

	useEffect(() => {
		id && fetchSceneDetail(id);
	}, [id]);

	const handleDeleteBtn = (imageId: number) => {
		setActiveImageKey(imageId);
		setVisible(true);
	};

	// API CALL

	const fetchSceneDetail = async (tourId: string) => {
		setIsLoading(true);
		dispatch(addScenesData([]));
		try {
			const response = await getTourDetail(tourId);
			response.data && setSceneDetail(response.data);
			response.data && dispatch(addScenesData([response.data]));
		} catch (error: any) {
			notifications.ERROR(
				"ERROR",
				error.response.data.message || DEFAULT_ERROR_MSG
			);
			navigation("/mark-location");
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const updateSceneDetail = async (sceneId: string, formData: any) => {
		setIsLoading(true);
		try {
			await updateScene(sceneId, formData);
			notifications.SUCCESS("SUCCESS", "Scene updated successfully");
			id && fetchSceneDetail(id);
			// response.data && setAllUserTrips(response.data);
			// fetchTrips();
			setDrawerOpen(false);
		} catch (error: any) {
			notifications.ERROR(
				"ERROR",
				error.response.data.message || DEFAULT_ERROR_MSG
			);
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const deleteGalleryImage = async () => {
		setIsUploading(true);
		try {
			await deleteGallery(id || "", activeImageKey);
			// fetchSceneDetail(sceneId);
			notifications.SUCCESS("SUCCESS", "Images deleted successfully");
			id && fetchSceneDetail(id);
			dispatch(updateRenderKey());
		} catch (error: any) {
			notifications.ERROR(
				"ERROR",
				error.response.data.message || DEFAULT_ERROR_MSG
			);
			// console.log("error", error);
		} finally {
			setIsUploading(false);
		}
	};

	const deleteTrip = async () => {
		try {
			await deleteTour(sceneDetail?.id || 0);
			navigation(`/mark-location?trip_id=${sceneDetail?.tripId}`);
		} catch (error) {
			console.log("error", error);
		} finally {
			console.log("deleteTrip");
		}
	};

	return (
		<>
			{sceneDetail && (
				// <MapPageLayout zoom={14} openSidePanel={true}>
					<Box>
						<FlexBox
							flexDirection={"row"}
							justifyContent={"space-between"}
						>
							<Breadcrumb
								className="tabs-breadcrumb"
								separator=">"
								items={brdeacrumbItems}
							/>
							<Box style={{ display: "flex", gap: 8 }}>
								<motion.div
									whileHover={{ scale: 1.1 }}
									transition={{
										type: "spring",
										stiffness: 400,
										damping: 10,
									}}
								>
									<Button
										type="primary"
										className="sm-btn scene-detail-edit"
										onClick={(event) => {
											// handleEdit(trip);
											setDrawerOpen(true);
											event.stopPropagation();
										}}
									>
										EDIT
									</Button>
								</motion.div>
								<motion.div
									whileHover={{ scale: 1.1 }}
									transition={{
										type: "spring",
										stiffness: 400,
										damping: 10,
									}}
								>
									<Button
										type="primary"
										danger
										className="sm-btn scene-detail-edit"
										onClick={(event) => {
											// handleEdit(trip);
											setConfirmSceneVisible(true);
											event.stopPropagation();
										}}
									>
										Delete
									</Button>
								</motion.div>
							</Box>
						</FlexBox>
						{isLoading ? (
							<SceneDetailSkeleton />
						) : (
							<>
								<FlexBox
									flexDirection={"row"}
									justifyContent={"flex-start"}
									gap={8}
									style={{ marginTop: 6 }}
								>
									<Paragraph
										fontSize={18}
										className="site-color f-bold"
									>
										{sceneDetail?.marker_name}
									</Paragraph>
									<Paragraph fontSize={12}>
										(
										{moment(sceneDetail?.visit_date).format(
											"MM/DD/YYYY"
										)}
										)
									</Paragraph>
								</FlexBox>
								<FlexBox
									flexDirection={"row"}
									justifyContent={"space-between"}
									style={{ margin: "4px 0" }}
								>
									<Paragraph fontSize={12}>
										{sceneDetail?.get_trip_data?.name}
									</Paragraph>
									<Paragraph fontSize={12}>
										{sceneDetail?.city}
									</Paragraph>
								</FlexBox>
								{sceneDetail?.address && (
									<FlexBox flexDirection={"row"} gap={2}>
										{/* <Paragraph fontSize={12}>Trip Name</Paragraph> */}
										<EnvironmentOutlined />
										<Paragraph fontSize={9}>
											{" "}
											: - {sceneDetail?.address}
										</Paragraph>
									</FlexBox>
								)}
								<FlexBox flexDirection={"row"} gap={2}>
									{/* <Paragraph fontSize={12}>Trip Name</Paragraph> */}
									<Paragraph
										fontSize={12}
										style={{
											marginTop: 8,
											lineHeight: 1.6,
										}}
									>
										{sceneDetail?.description}
									</Paragraph>
								</FlexBox>
								<FlexBox className="scene-detail">
									<Paragraph
										className="scene-detail-heading site-color"
										fontSize={18}
									>
										Gallery
									</Paragraph>
									{/* <Box>
									<MultiImages
										title={"Upload Profile Picture"}
										uploadFunction={handleGalleryImage}
									/>
									<Button
										type="primary"
										className="primary-btn"
										onClick={handleUpload}
										loading={isUploading}
										disabled={isUploading}
									>
										Upload
									</Button>
								</Box> */}
									<Box className="scene-detail-image">
										<Image.PreviewGroup
											preview={{
												onChange: (current, prev) =>
													console.log(
														`current index: ${current}, prev index: ${prev}`
													),
											}}
										>
											{sceneDetail?.images.map(
												(
													imgUrl: string,
													index: number
												) => (
													<Box
														className="scene-detail-image-single"
														key={index}
													>
														<Image src={imgUrl} />
														<DeleteOutlined
															onClick={(
																event
															) => {
																handleDeleteBtn(
																	index
																);
																event.stopPropagation();
															}}
															className="scene-detail-image-single-del-icon"
														/>
													</Box>
												)
											)}
										</Image.PreviewGroup>
									</Box>
									{sceneDetail?.images.length === 0 && (
										<Empty
											image={Empty.PRESENTED_IMAGE_SIMPLE}
											description={
												"No image found. Please add some gallery images."
											}
										/>
									)}
								</FlexBox>
							</>
						)}
					</Box>
				// </MapPageLayout>
			)}

			<EditSceneDrawer
				drawerOpen={drawerOpen}
				setDrawerOpen={setDrawerOpen}
				sceneDetail={sceneDetail}
				updateSceneDetail={updateSceneDetail}
			/>

			<ConfirmModal
				isVisible={visible}
				setIsVisible={setVisible}
				callbackFunction={deleteGalleryImage}
				description="delete this image"
			/>

			<ConfirmModal
				isVisible={confirmSceneVisible}
				setIsVisible={setConfirmSceneVisible}
				callbackFunction={deleteTrip}
				description="delete this scene"
			/>
		</>
	);
};

export default SceneDetail;
