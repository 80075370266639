import React from "react";
import { Skeleton } from "antd";
import { UploadOutlined } from '@ant-design/icons';

import "components/skeleton/style.scss";
import { Box } from "components/StyledComponents";

const SceneDetailSkeleton = () => {
	return (
		<>
			<Skeleton className="scene-detail-skeleton" active />
			<Skeleton.Node active={true}>
				{/* <DotChartOutlined style={{ fontSize: 40, color: "#bfbfbf" }} /> */}
                <UploadOutlined style={{ fontSize: 40, color: "#bfbfbf" }} />
			</Skeleton.Node>
            <br />
            <br />
            <Box className="scene-detail-skeleton-images">
                {[1,2,3,4,5].map((num: number, index: number) => <Skeleton.Image key={index} active={true} />)}
            </Box>
		</>
	);
};

export default SceneDetailSkeleton;
