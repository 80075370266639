import React, { useEffect, useState } from "react";
import { Input, Select, DatePicker } from "antd";
import dayjs from "dayjs";

import {
	Box,
	Container,
	FlexBox,
	Paragraph,
} from "components/StyledComponents";

import { DownOutlined, FilterOutlined } from "@ant-design/icons";

import { UseAppDispatch } from "hooks/hooks";
import { getCities, getTripList } from "services/map";

import { ITrip } from "types";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MARKER_TYPES } from "constants/Map";

import "pages/map/style.scss";
import { useMediaQuery } from "react-responsive";

interface IProps {
	filterHeight: number;
}

const { Search } = Input;

const FilterForm = ({ filterHeight }: IProps): JSX.Element => {

	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
	const isLaptop = useMediaQuery({ query: "(max-width: 1224px)" });
	const isTablet = useMediaQuery({ query: "(max-width: 768px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
	
	const location = useLocation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	// console.log("searchParams >> ", searchParams, Object.fromEntries([...searchParams]));
	const allSearchParams = Object.fromEntries([...searchParams]);
	

	const isFilterPage =
		location.pathname === "/mark-location/filter" ? true : false;

	const [allUserTrips, setAllUserTrips] = useState<Array<ITrip>>([]);
	const [allUserCities, setAllUserCities] = useState<
		Array<{ label: string; value: string }>
	>([]);
	const [isFilterActive, setFilterActive] = useState<boolean>(false);

	useEffect(() => {
		fetchTrips();
		fetchFiltersInput();
		console.log("allSearchParams >> ", Object.values(allSearchParams).length);
		Object.values(allSearchParams).length > 0 && setFilterActive(true);
	}, []);

	const handleFilterChange = (keyName: string, value: string) => {
		if (!isFilterPage) {
			navigate(`/mark-location/filter?${keyName}=${value}`);
			return;
		}
		if (value) {
			searchParams.set(keyName, value);
			setSearchParams(searchParams);
		} else {
			searchParams.delete(keyName);
			setSearchParams(searchParams);
		}
	};

	const getDefaultValue = (keyName: string) => {
		return searchParams.get(keyName) || "";
	};

	const onSearch = (value: string) => {
		console.log("search:", value);
	};

	// Filter Inputs Params

	const cityParams = getDefaultValue("city")
		? { defaultValue: getDefaultValue("city") }
		: {};

	const tripParams = getDefaultValue("trip")
		? { defaultValue: getDefaultValue("trip") }
		: {};

	const typeParams = getDefaultValue("type")
		? { defaultValue: getDefaultValue("type") }
		: {};

	const dateParams = getDefaultValue("date")
		? { defaultValue: dayjs(getDefaultValue("date"), "YYYYMM/DD") }
		: {};

	// API CALL

	const fetchTrips = async () => {
		// setIsLoading(true);
		try {
			const response: any = await getTripList();
			const tripOptions =
				response.data &&
				response.data.map((trip: ITrip, index: number) => {
					return {
						value: `${trip.id}`,
						label: trip.name,
					};
				});
			tripOptions && setAllUserTrips(tripOptions);
		} catch (error) {
			console.log(error);
		} finally {
			// setIsLoading(false);
		}
	};

	const fetchFiltersInput = async () => {
		// setIsLoading(true);
		try {
			const response: any = await getCities();
			const citiesOptions = Object.values(response.data).map(
				(city: any, index: number) => {
					return {
						value: city,
						label: city,
					};
				}
			);
			setAllUserCities(citiesOptions);
			// console.log("response: ",citiesOptions, response.data);
		} catch (error) {
			console.log(error);
		} finally {
			// setIsLoading(false);
		}
	};

	return (
		<Box className="filter-section" style={{ maxHeight: filterHeight === 0 ? 0 : `200px`, padding: filterHeight === 0 ? 0 : '12px 0' }}>
			<Container>
				<FlexBox flexDirection="row" gap={40} alignItems={"center"}>
					<Paragraph>Filters</Paragraph>
					<FlexBox flexDirection="row" alignItems="center" gap={30} flexWrap={'wrap'} style={{ rowGap: 8 }}>
						{/* <Search
                            {...inputParams}
                            placeholder="City, State"
                            style={{ width: 200 }}
                            className="search-input"
                            onSearch={(value: string) => {
                                handleFilterChange('city', value);
                            }}
                        /> */}
						<Select
							{...cityParams}
							suffixIcon={<DownOutlined className="svg-gray" />}
							showSearch
							optionFilterProp="children"
							options={allUserCities}
							onChange={(value) => {
								handleFilterChange("city", value);
							}}
							onSearch={onSearch}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							allowClear
							placeholder={"Select City"}
						/>
						<Select
							{...tripParams}
							suffixIcon={<DownOutlined className="svg-gray" />}
							options={allUserTrips}
							onChange={(value) => {
								handleFilterChange("trip", value);
							}}
							allowClear
							placeholder={"Select Trip"}
						/>
						<Select
							{...typeParams}
							suffixIcon={<DownOutlined className="svg-gray" />}
							options={MARKER_TYPES}
							onChange={(value) => {
								handleFilterChange("type", value);
							}}
							allowClear
							placeholder={"Select Type"}
						/>
						<DatePicker
							{...dateParams}
							format={"YYYY/MM/DD"}
							className="date-picker"
							placeholder="Date"
							allowClear
							onChange={(date, dateString) => {
								handleFilterChange("date", dateString);
							}}
						/>
					</FlexBox>
				</FlexBox>
			</Container>
		</Box>
	);
};

export default FilterForm;
