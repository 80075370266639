import React, { useEffect, useState } from 'react'
import { message, Spin, Upload } from 'antd'
// import { Box } from "../../components/styledComponents";
// import { Camera, Edit2 } from '../icons';
import { CameraOutlined, EditOutlined } from '@ant-design/icons';
import "./style.scss"

interface IProps {
    title: string,
    uploadFunction: any,
    initialImage?: string;
    resetImage?: boolean;
}

const SingleImage = ({ title, uploadFunction, initialImage = "", resetImage }: IProps) => {

    const [userCompanyLogoUrl, setUserCompanyLogoUrl] = useState<string>(initialImage);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    useEffect(() => {
        setUserCompanyLogoUrl("");
    }, [resetImage])

    function getBase64(img: any, callback: any) {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file: any) {
        console.log(file.type);
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG/WEBP file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }
        return isJpgOrPng && isLt2M;
    }

    async function handleChange(info: any) {
        // setImgFile(info.file.originFileObj);
        getBase64(info.file.originFileObj, (imageUrl: any) => {
            setUserCompanyLogoUrl(imageUrl);
        });
        // if (info.file.status === "uploading") {
        //     // this.setState({ loading: true });
        //     setIsUploading(true);
        //     return;
        // }
        // if (info.file.status === "done") {
        //     // Get this url from response in real world.
        //     getBase64(info.file.originFileObj, async (imageUrl: any) => {
        //         // setIsUploading(false);
        //         setUserCompanyLogoUrl(imageUrl);
        //     });
        // }
        // const fileData = new FormData();
        // fileData.append('file', info.file.originFileObj);
        // console.log("info.file.originFileObj >>", info.file.originFileObj);
        await uploadFunction(info.file.originFileObj)
            .then((res: string) => {
                // res && console.log("res >>> ", res);
                res && setUserCompanyLogoUrl(res);
            })
        setIsUploading(false);
        // setUserCompanyLogoUrl(userData()?.profileImagePresignedUrl)
    }

    const uploadButton = (
        <CameraOutlined />
    );

    return (
        <>
            <Upload
                name="avatar"
                listType="picture-card"
                className="single-image-upload"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                disabled={isUploading}
                style={{ width: "fit-content" }}
            >
                <Spin spinning={isUploading} style={{ height: "100%" }}>
                    {userCompanyLogoUrl ? (
                        <div style={{ height: "100%" }}>
                            <img
                                src={userCompanyLogoUrl}
                                alt="avatar"
                                style={{ width: "100%", maxHeight: "300px", objectFit: "cover", borderRadius: "5px" }}
                            />
                            <span className="edit-image"><EditOutlined /></span>
                        </div>
                    ) : (
                        uploadButton
                    )}
                </Spin>
            </Upload>
            {/* {title !== "none" && (<label style={{ color: "#111111", marginLeft: "15px", display: "inline-block" }}>{title} <br /><span style={{ color: "#707070" }}>(optional)</span></label>)} */}
        </>
    )
}

export default SingleImage
