import React, { useEffect, useState } from "react";

import { Features } from "./Features";
import { SiteObjectiveOne } from "./SiteObjectiveOne";

import { SiteObjectiveTwo } from "./SiteObjectiveTwo";
import { VideoSection } from "./VideoSection";
import { Gallery } from "./Gallery";
import { Banner } from "./Banner";
import { getHomeData } from "services/pages";
import { notifications } from "components/notifications";
import { DEFAULT_ERROR_MSG } from "constants/Common";

import "pages/home/style.scss";

const Home: React.FC = () => {
	const [content, setContent] = useState<any>(null);

	useEffect(() => {
		fetchHome();
	}, []);

	// API CALL

	const fetchHome = async () => {
		try {
			const response: any = await getHomeData();
			console.log(response);
			setContent(response.data);
		} catch (error: any) {
			// console.log("error.response.data.errors >>", error.response.data.errors);
			notifications.ERROR(
				"ERROR",
				error.response.data.errors ||
					error.response.data.message ||
					DEFAULT_ERROR_MSG
			);
		} finally {
			console.log("finally");
		}
	};

	return (
		<>
			{content && (
				<>
					<Banner content={content.banner.banner} />
					{/* <Features content={content.feature} /> */}
					{
						Object.values(content.feature).map((siteFeature: any, index: number) => {
							return index % 2 === 0 ? <SiteObjectiveOne content={siteFeature} /> :  <SiteObjectiveTwo content={siteFeature} />;
						})
					}
					{/* <SiteObjectiveOne content={content.side_objective_1.side_objective_1} />
					<SiteObjectiveTwo content={content.side_objective_1.side_objective_1} /> */}
					{/* <VideoSection content={content.banner_2.banner_2} /> */}
					<Gallery content={content.gallery.gallery} />
				</>
			)}
		</>
	);
};

export default Home;
