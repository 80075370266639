import React from "react";
import { Row, Col, List } from "antd";
import { useMediaQuery } from "react-responsive";

import {
	Box,
	Container,
	FlexBox,
	Paragraph,
	Title,
} from "components/StyledComponents";

import { motion, Variants } from "framer-motion";

import { EnvironmentFilled } from "@ant-design/icons";

import MAP1 from "assets/images/home/map_1.png";

import "pages/home/style.scss";

interface IProps {
	content: any;
}

export const SiteObjectiveOne = ({ content }: IProps) => {
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
	const isLaptop = useMediaQuery({ query: "(max-width: 1224px)" });
	const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 580px)" });

	const listData: Array<string> = [
		"Lorem Ipsum is true, Lorem Ipsum is false and Lorem Ipsum is",
		"Lorem Ipsum is true, Lorem Ipsum is false and Lorem Ipsum is",
		"Lorem Ipsum is true, Lorem Ipsum is false and Lorem Ipsum is",
		"Lorem Ipsum is true, Lorem Ipsum is false and Lorem Ipsum is",
		"Lorem Ipsum is true, Lorem Ipsum is false and Lorem Ipsum is",
	];

	const cardVariants: Variants = {
		offscreen: {
			x: -100,
		},
		onscreen: {
			x: 0,
			rotate: 0,
			transition: {
				type: "spring",
				bounce: 0.4,
				duration: 0.8,
			},
		},
	};

	const TitleVariants: Variants = {
		offscreen: {
			opacity: 0,
		},
		onscreen: {
			opacity: 1,
			rotate: 0,
			transition: {
				type: "spring",
				duration: 2.5,
			},
		},
	};

	return (
		<>
			<Box className="section-padding">
				<Container>
					<Box>
						<Row gutter={{ lg: 32 }} align="middle">
							<Col lg={12}>
								<motion.div
									style={{
										marginLeft: isTablet ? "0px" : "0px",
									}}
									initial={"offscreen"}
									whileInView={"onscreen"}
									viewport={{ once: true }}
									variants={cardVariants}
									transition={{
										type: "spring",
										stiffness: -1000,
										damping: -200,
									}}
								>
									<img
										src={`${process.env.REACT_APP_DOMAIN_URL}${content.feature_image}`}
										alt="Map"
									/>
								</motion.div>
							</Col>
							<Col lg={12}>
								<FlexBox gap={16}>
									<Box>
										<motion.div
											initial={"offscreen"}
											whileInView={"onscreen"}
											viewport={{ once: true }}
											variants={TitleVariants}
											transition={{
												type: "spring",
												stiffness: -1000,
												damping: -200,
											}}
										>
											<Title level={3}>
												<span className="site-color">
													{content.feature_heading}
												</span>
											</Title>
										</motion.div>
										<motion.div
											initial={"offscreen"}
											whileInView={"onscreen"}
											viewport={{ once: true }}
											variants={TitleVariants}
											transition={{
												type: "spring",
												stiffness: -1000,
												damping: -200,
											}}
										>
											<Title
												level={5}
												style={{ fontSize: 22 }}
											>
												{content.feature_sub_heading}
											</Title>
										</motion.div>
									</Box>
									<motion.div
										initial={"offscreen"}
										whileInView={"onscreen"}
										viewport={{ once: true }}
										variants={TitleVariants}
										transition={{
											type: "spring",
											stiffness: -1000,
											damping: -200,
										}}
									>
										<Paragraph>
											{content.feature_description}
										</Paragraph>
									</motion.div>
									{/* <List
										dataSource={content.list}
										renderItem={(item: string) => (
											<List.Item
												style={{
													display: "flex",
													gap: "12px",
													justifyContent:
														"flex-start",
													padding: "4px 0",
												}}
											>
												<EnvironmentFilled
													style={{ color: "orange" }}
												/>
												{item}
											</List.Item>
										)}
									/> */}
								</FlexBox>
							</Col>
						</Row>
					</Box>
				</Container>
			</Box>
		</>
	);
};
