import { Button, Modal } from 'antd'
import { Box, Paragraph } from 'components/StyledComponents';
import React, { ReactNode, useEffect, useState } from 'react'

interface IProps {
    isVisible: boolean,
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
    callbackFunction: () => Promise<void>,
    callbackOnCancel?: () => Promise<void>,
    description?: string
    isLoading?: boolean;
    descriptionElements?: ReactNode;
}

const msg = "save the data before you exit";

const ConfirmModal = ({ isVisible, setIsVisible, callbackFunction, callbackOnCancel, description = msg, isLoading, descriptionElements }: IProps): JSX.Element => {

    const [visible, setVisible] = useState<boolean>(isVisible);

    useEffect(() => {
        setVisible(isVisible);
    }, [isVisible])

    const hideModal = async () => {
        await callbackFunction();
        setVisible(false);
        setIsVisible(false)
    };

    const cancelModal = () => {
        setVisible(false)
        setIsVisible(false)
        callbackOnCancel && callbackOnCancel()
    }

    return (
        <>
            <Modal
                open={visible}
                footer={false}
                className="confirm-modal"
                width={360}
                onCancel={cancelModal}
                title="Are you sure?"
                closeIcon={<></>}
                centered
            >
                <Box display="flex" style={{ flexDirection: "column", margin: "16px 0", textAlign: "right" }}>
                    <Paragraph fontSize={14} style={{ margin: 0 }}>Do you really want to {description}?</Paragraph>
                    {descriptionElements && descriptionElements}
                </Box>
                <Box display="flex" style={{ justifyContent: "flex-end", gap: "20px" }}>
                    <Button className="sm-btn" disabled={isLoading || false} onClick={cancelModal}>Cancel</Button>
                    <Button className="sm-btn" type="primary" onClick={hideModal} disabled={isLoading || false} loading={isLoading || false}>Confirm</Button>
                </Box>
            </Modal>
        </>
    )
}

export default ConfirmModal
