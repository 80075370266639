import React from "react";
import { Row, Col, Button } from "antd";

import {
	Box,
	Container,
	FlexBox,
	Paragraph,
	Title,
} from "components/StyledComponents";

import { motion, Variants } from "framer-motion";

import Banner_Footer_BG from "assets/images/home/background_footer.png";
import Banner_IMG from "assets/images/home/banner_img.jpg";

interface IProps {
	content: any;
}

export const Banner = ({ content }: IProps) => {
	const TitleVariants: Variants = {
		offscreen: {
			y: -20,
		},
		onscreen: {
			y: 0,
			rotate: 0,
			transition: {
				type: "spring",
				duration: 1.2,
			},
		},
	};

	const OpacityVariants: Variants = {
		offscreen: {
			opacity: 0,
		},
		onscreen: {
			opacity: 1,
			rotate: 0,
			transition: {
				type: "spring",
				duration: 1.2,
			},
		},
	};

	const InfiniteVariants: Variants = {
		offscreen: {
			opacity: 0,
		},
		onscreen: {
			opacity: 1,
			rotate: 0,
			transition: {
				type: "spring",
				duration: 1.2,
			},
		},
	};

	return (
		<>
			<Box className="home-banner">
				<Container>
					<Row gutter={[{ md: 45 }, { xs: 32, sm: 32 }]}>
						<Col lg={14}>
							<FlexBox gap={24}>
								<motion.div
									initial={"offscreen"}
									whileInView={"onscreen"}
									viewport={{ once: true }}
									variants={TitleVariants}
									transition={{
										type: "spring",
										stiffness: -1000,
										damping: -200,
									}}
								>
									<Title className="banner-title" level={1}>
										{content?.heading}
									</Title>
								</motion.div>
								<motion.div
									initial={"offscreen"}
									whileInView={"onscreen"}
									viewport={{ once: true }}
									variants={TitleVariants}
									transition={{
										type: "spring",
										stiffness: -1000,
										damping: -200,
									}}
								>
									<Paragraph fontSize={16}>
										{content.sub_heading}
									</Paragraph>
								</motion.div>
								<FlexBox flexDirection="row" gap={24}>
									<motion.div
										initial={"offscreen"}
										whileInView={"onscreen"}
										viewport={{ once: true }}
										variants={OpacityVariants}
										transition={{
											type: "spring",
											stiffness: -1000,
											damping: -200,
										}}
									>
										<Button type="primary">
											Get Started
										</Button>
									</motion.div>
									<motion.div
										initial={"offscreen"}
										whileInView={"onscreen"}
										viewport={{ once: true }}
										variants={OpacityVariants}
										transition={{
											type: "spring",
											stiffness: -1000,
											damping: -200,
										}}
									>
										<Paragraph className="banner-btn-help-text">
											{content.description}
										</Paragraph>
									</motion.div>
								</FlexBox>
							</FlexBox>
						</Col>
						<Col lg={10}>
							<Box className="banner-image">
								<motion.div
									// initial={"offscreen"}
									// whileInView={"onscreen"}
									// viewport={{ once: true }}
									// variants={InfiniteVariants}
									animate={{ y: [-15, 15, -15] }}
									transition={{
										repeat: Infinity,
										duration: 12,
										ease: "linear"
									}}
								>
									<img
										src={`${process.env.REACT_APP_DOMAIN_URL}${content.image}`}
										alt="Banner"
									/>
								</motion.div>
							</Box>
						</Col>
					</Row>
				</Container>

				<Box className="banner-footer-bg">
					<img src={Banner_Footer_BG} alt="banner-footer" />
					{/* <video src=""></video> */}
					{/* <iframe
						class="bg-video"
						src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1&mute=1&controls=0&loop=1&playlist=dQw4w9WgXcQ"
						frameBorder="0"
						allowFullScreen
						allow="autoplay"
					/> */}
				</Box>
			</Box>
		</>
	);
};
