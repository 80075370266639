import axios from "axios";

export const baseApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});


// Add a request interceptor
baseApi.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if(token){
      config.headers["Authorization"] = `Bearer ${token}`
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
baseApi.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if(error.response.status === 401){
      localStorage.clear();
      window.location.href = "https://dsproject.in/map/";
    }
    return Promise.reject(error);
  }
);
