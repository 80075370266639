import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IScene, ITrip } from "types";

interface IProps {
    sceneData: Array<IScene> | null;
    reRender: boolean;
    isCollapse: boolean;
    activeTrip: ITrip | null;
    newMarkerColor: string;
    newAutoCompleteAddress: string;
    markerCity: string;
}

const initialState: IProps = {
    sceneData: null,
    reRender: false,
    isCollapse: false,
    activeTrip: null,
    newMarkerColor: "",
    newAutoCompleteAddress: "",
    markerCity: ""
};

export const mapSlice = createSlice({
    name: "map",
    initialState: initialState,
    reducers: {
        addScenesData: (state, action: PayloadAction<any>) => {
            state.sceneData = action.payload;
        },
        updateRenderKey: (state) => {
            state.reRender = !state.reRender
        },
        toggleCollapse: (state) => {
            state.isCollapse = !state.isCollapse
        },
        collapseTrue: (state) => {
            state.isCollapse = true;
        },
        addCurrentTrip: (state, action: PayloadAction<any>) => {
            state.activeTrip = action.payload;
        },
        addNewMarkerColor: (state, action: PayloadAction<any>) => {
            state.newMarkerColor = action.payload;
        },
        addNewAutoCompleteAddress: (state, action: PayloadAction<any>) => {
            state.newAutoCompleteAddress = action.payload;
        },
        addNewMarkerCity: (state, action: PayloadAction<any>) => {
            state.markerCity = action.payload;
        }
    }
});

export const { addScenesData, updateRenderKey, toggleCollapse, addNewMarkerColor, addNewAutoCompleteAddress, collapseTrue, addNewMarkerCity } = mapSlice.actions;
export default mapSlice.reducer;