import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { registerUserData, googleLogin, loginUser, logoutUser } from "../../services/auth";

interface IUser{
	fullname: string;
	user_email: string;
	isNewUser: number;
	totalTrips: number;
	tourStatus: number;
}

interface IProps {
	user: IUser | null;
	isLoading: boolean;
	loginError: boolean;
	errorText: string;
	token: string | null;
	isNew: boolean;
	hideWelcomePopup: boolean;
}

const initialState: IProps = {
	user: null,
	isLoading: false,
	loginError: false,
	errorText: "",
	token: null,
	isNew: false,
	hideWelcomePopup: false
};

export const authSlice = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {
		getUser: (state, action: PayloadAction<any>) => {
			state.user = action.payload;
		},
		addAuthData: (state, {payload}) => {
			state.user = payload.user;
			state.token = payload.token;
			state.hideWelcomePopup = false;
			localStorage.setItem("token", payload.token);
		},
		authLogout: (state) => {
			state.user = null;
            state.token = null;
            localStorage.removeItem("token");
		},
		updateNewUserKey: (state, {payload}) => {
			if(state.user?.isNewUser){
				state.user.isNewUser = payload;
			}
		},
		updateWelcomeKey: (state) => {
			state.hideWelcomePopup = true;
		},
		updateTotalTrips: (state, {payload}) => {
			if(state.user?.totalTrips){
				state.user.totalTrips = payload;
			}
		},
		updateTourStatusAuth: (state, {payload}) => {
			if(state.user){
				state.user.tourStatus = payload;
			}
		}
	},
	extraReducers: (builder) => {
		builder.addCase(registerUser.pending, (state, action) => {
			state.isLoading = true;
			state.loginError = false;
		});
		builder.addCase(registerUser.fulfilled, (state, {payload}: any) => {
			state.isLoading = false;
			state.loginError = false;
			state.user = payload.data;
			state.token = payload.data.token;
			localStorage.setItem("token", payload.data.token);
		});
		builder.addCase(registerUser.rejected, (state, action) => {
			state.isLoading = false;
			state.loginError = true;
			state.errorText = action.error.message || "Something went wrong";
		});
		
		builder.addCase(authUser.pending, (state, action) => {
			state.isLoading = true;
			state.loginError = false;
		});
		builder.addCase(authUser.fulfilled, (state, {payload}: any) => {
			state.isLoading = false;
			state.loginError = false;
			state.user = payload.data;
			state.token = payload.data.token;
			localStorage.setItem("token", payload.data.token);
		});
		builder.addCase(authUser.rejected, (state, action) => {
			state.isLoading = false;
			state.loginError = true;
			state.errorText = action.error.message || "Something went wrong";
		});

		builder.addCase(authGoogle.pending, (state, action) => {
			state.isLoading = true;
			state.loginError = false;
		});
		builder.addCase(authGoogle.fulfilled, (state, {payload}: any) => {
			state.isLoading = false;
			state.loginError = false;
			state.user = payload.data;
			state.token = payload.data.token;
			localStorage.setItem("token", payload.data.token);
		});
		builder.addCase(authGoogle.rejected, (state, action) => {
			state.isLoading = false;
			state.loginError = true;
			state.errorText = action.error.message || "Something went wrong";
		});
	},
});

export const registerUser = createAsyncThunk(
	"auth/registerUser",
	async (data: any) => {
		try{
			const userData = await registerUserData(data);
			return userData;
		}catch(error: any){
			return Promise.reject(error.response.data);
		}
	}
);

export const authUser = createAsyncThunk(
	"auth/authUser",
	async (data: any) => {
		try{
			const response = await loginUser(data);
			return response;
		}catch(error: any){
			return Promise.reject(error.response.data)
		}
	}
)

export const authGoogle = createAsyncThunk(
	"auth/authGoogle",
	async (data: any) => {
		try{
			const response = await googleLogin(data);
			return response;
		}catch(error: any){
			return Promise.reject(error.response.data)
		}
	}
)

export const authLogouts = createAsyncThunk(
	"auth/authLogout",
	async () => {
		try{
			const response = await logoutUser();
			return response;
		}catch(error: any){
			return Promise.reject(error.response.data)
		}
	}
)

export const { getUser, addAuthData, authLogout, updateNewUserKey, updateWelcomeKey, updateTotalTrips, updateTourStatusAuth } = authSlice.actions;
export default authSlice.reducer;
