import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import {
	Box,
	Container,
	FlexBox,
} from "components/StyledComponents";
import LOGO from "assets/images/logo.png";

const MapFooter = () => {
	return (
		<Box className="footer-top map-footer-top" style={{ padding: "8px 0" }}>
			<Container>
				<Row gutter={{ md: 64 }}>
					<Col lg={24}>
						<FlexBox
							flexDirection={"row"}
							justifyContent={"space-between"}
							gap={20}
						>
							<Link to="/" style={{ maxWidth: 100 }}>
								<img src={LOGO} alt="Logo" />
							</Link>
							<FlexBox gap={16}>
								<FlexBox flexDirection={"row"} gap={16} flexWrap={'wrap'}>
									<Link to="/">Home</Link>
									{/* <Link to="/">Blog</Link>
									<Link to="/">Group Tracking</Link> */}
									<Link to="/terms-conditions">Terms</Link>
									<Link to="/privacy-policy">Privacy Policy</Link>
								</FlexBox>
							</FlexBox>
						</FlexBox>
					</Col>
					{/* <Col lg={8}>
						</Col> */}
				</Row>
			</Container>
		</Box>
	);
};

export default MapFooter;
