import React from 'react';
import { Routes, Route } from "react-router-dom";

import Home from "pages/home/";
import TermsAndConditions from "pages/termsAndConditions/";
import Signup from "pages/auth/Signup";
import MapMarker from "pages/map/index";
import Login from "pages/auth/Login";
import ProtectedLayout from "components/layouts/ProtectedLayout"
import AuthLayout from "components/layouts/AuthLayout"
import Layout from "components/layouts";
// import SceneDetail from 'pages/map/SceneDetail';
import Filter from 'pages/map/filter';
import ForgotPassword from 'pages/auth/ForgotPassword';
import ResetPassword from 'pages/auth/ResetPassword';
import PrivacyPolicy from 'pages/privacyPolicy';

const Routing: React.FC = () => {
	return (
		<Routes>
			<Route element={<Layout /> }>
				<Route path="" element={<Home />} />
				<Route path="terms-conditions" element={<TermsAndConditions />} />
				<Route path="privacy-policy" element={<PrivacyPolicy />} />

				<Route element={<AuthLayout /> }>
					<Route path="/login" element={<Login />} />
					<Route path="/signup" element={<Signup />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/reset-password" element={<ResetPassword />} />
				</Route>

				<Route element={<ProtectedLayout />}>
					<Route path="/mark-location" element={<MapMarker />} />
					{/* <Route path="/mark-location/scene-detail/:id" element={<SceneDetail />} /> */}
					<Route path="/mark-location/filter" element={<Filter />} />
				</Route>
				
			</Route>
		</Routes>
	);
};

export default Routing;
