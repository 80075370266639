import { Box, Container, Title } from "components/StyledComponents";
import { notifications } from "components/notifications";
import { DEFAULT_ERROR_MSG } from "constants/Common";
import React, { useEffect, useState } from "react";
import { getSitePages } from "services/pages";

const PrivacyPolicy = () => {

    const [content, setContent] = useState<string>("");

	useEffect(() => {
		fetchTerms();
	}, []);

	// API CALL

	const fetchTerms = async () => {
		try {
			const response: any = await getSitePages('privacy');
			console.log(response);
            setContent(response.data.content);
		} catch (error: any) {
			// console.log("error.response.data.errors >>", error.response.data.errors);
			notifications.ERROR(
				"ERROR",
				error.response.data.errors ||
					error.response.data.message ||
					DEFAULT_ERROR_MSG
			);
		} finally {
			console.log("finally");
		}
	};

	return (
		<>
			<Box className="section-padding ">
				<Container>
					<Title level={3} style={{ textAlign: "center" }}>
						PRIVACY POLICY
					</Title>
                    <Box className="section-padding" dangerouslySetInnerHTML={{ __html: content }} />
				</Container>
			</Box>
		</>
	);
};

export default PrivacyPolicy;
