import React, { useState } from "react";
import { Row, Col, Form, Input, Button } from "antd";
import { AxiosResponse } from "axios";

import {
	Box,
	Container,
	FlexBox,
	Paragraph,
	Title,
} from "components/StyledComponents";

import { UseAppDispatch } from "../../hooks/hooks";
import { registerUserData } from "../../services/auth";
import { IRegisterForm } from "../../types";

import { addAuthData } from "store/slices/authSlice";
import SocialLogin from "./SocialLogin";

import "pages/auth/style.scss";
import { notifications } from "components/notifications";
import { DEFAULT_ERROR_MSG } from "constants/Common";
import { Link } from "react-router-dom";

const Signup: React.FC = () => {

	const dispatch = UseAppDispatch();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onFinish = (values: IRegisterForm) => {
		registerUser(values);
	};

	// API
	const registerUser = async (userData: IRegisterForm) => {
		setIsLoading(true);
		try {
			const response: AxiosResponse<any> = await registerUserData(userData);
			const authData = {
				user: {
					fullname: response.data.fullname,
                    user_email: response.data.user_email,
                    isNewUser: response.data.isNewUser,
					totalTrips: response.data.totalTrips,
					tourStatus: response.data.tourStatus,
				},
				token: response.data.token,
			}
			dispatch(addAuthData(authData));
			// window.location.href = 'http://localhost:3000/mark-location';
		} catch (error: any) {
			notifications.ERROR("ERROR", error.response.data.errors || error.response.data.message || DEFAULT_ERROR_MSG);
		} finally{
			setIsLoading(false);
		}
	}

	return (
		<>
			<Box className="auth-page">
                <Box className="auth-section-image">
                    {/* <img src={LOGIN_BG} alt="Login" /> */}
                </Box>
				<Container>
					<Box className="auth-section">
						<Row gutter={{ lg: 120 }}>
							<Col lg={12}>
								
							</Col>
							<Col lg={12}>
								<Box className="auth-section-form">
									<Title level={2}>Sign Up</Title>
									<Form
										name="basic"
										layout="vertical"
										initialValues={{
											name: "",
											email: "",
											password: "",
										}}
										onFinish={onFinish}
										autoComplete="off"
									>
										<FlexBox
											className="auth-section-form-inputs"
											// gap={20}
										>
											<Form.Item
												label="Name"
												name="name"
												rules={[{ required: true, message: "Please input your name!" }]}
											>
												<Input />
											</Form.Item>

											<Form.Item
												label="Email"
												name="email"
												rules={[
													{ required: true, message: "Please input your email!" },
													{ type: "email", message: "Please enter valid email!" },
												]}
											>
												<Input />
											</Form.Item>

											<Form.Item
												label="Password"
												name="password"
												rules={[{ required: true, message: "Please input your password!" }]}
											>
												<Input.Password />
											</Form.Item>
										</FlexBox>

										<Form.Item className="auth-section-form-forgot-password">
											<Link to="/login">Have an Account? Login</Link>
										</Form.Item>

										<Form.Item
											style={{
												marginBottom: 0,
												textAlign: "center",
											}}
										>
											<Button
												type="primary"
												htmlType="submit"
												block
												className="round-btn"
												loading={isLoading}
												disabled={isLoading}
											>
												Create Account
											</Button>
										</Form.Item>
									</Form>
									<Paragraph fontSize={14} textAlign={"center"}>or Sign Up using</Paragraph>
									<SocialLogin setIsLoading={setIsLoading} />
								</Box>
							</Col>
						</Row>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default Signup;
