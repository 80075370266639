import React from 'react'
import { UseAppSelector } from '../../hooks/hooks'
import { Navigate, Outlet } from 'react-router-dom';

const AuthLayout: React.FC = () => {

    const token = UseAppSelector(state => state.auth.token);

    if(token){
        // return <Navigate to="/mark-location" />
        window.location.href = `${process.env.REACT_APP_SITE_URL}`;
    }

    return (
        <Outlet />
    )
}

export default AuthLayout