import { IResetPasswordRequest } from "types";
import { baseApi } from ".";

export const registerUserData = (data: any) => baseApi.post("signup", data);
export const loginUser = (data: any) => baseApi.post("login", data);
export const logoutUser = () => baseApi.post("logout");
export const forgetPassword = (data: {email: string}) => baseApi.post("forget-password", data);
export const resetPassword = (data: IResetPasswordRequest) => baseApi.post("reset-password", data);

export const googleLogin = (data: {name: string, email: string}) => baseApi.post("google-login", data);

export const updateTourStatus = (data: {tour_status: number}) => baseApi.post("tour-status-update", data);