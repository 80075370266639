import React from "react";
import { Row, Col } from "antd";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import {
	Box,
	Container,
	FlexBox,
	Paragraph,
	Title,
} from "components/StyledComponents";

import { motion, Variants } from "framer-motion";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import IMG1 from "assets/images/home/map_1.png";
import IMG2 from "assets/images/home/map_2.png";

interface IProps {
	content: any;
}

export const Gallery = ({ content }: IProps) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
        autoplay: true
	};

	const images = [
		{ url: IMG1 },
		{ url: IMG2 },
		{ url: IMG1 },
		{ url: IMG2 },
		{ url: IMG1 },
		{ url: IMG2 },
		{ url: IMG1 },
		{ url: IMG2 },
	];

	const TitleVariants: Variants = {
		offscreen: {
			y: -60,
		},
		onscreen: {
			y: 0,
			rotate: 0,
			transition: {
				type: "spring",
				duration: 1.5,
			},
		},
	};

	return (
		<>
			<Box className="section-padding gallery-section">
				<Container>
					<FlexBox gap={32}>
						<FlexBox className="section-headings">
							<motion.div
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true }}
								variants={TitleVariants}
								transition={{
									type: "spring",
									stiffness: -1000,
									damping: -200,
								}}
							>
								<Title level={2}>{content.heading}</Title>
							</motion.div>
							<motion.div
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true }}
								variants={TitleVariants}
								transition={{
									type: "spring",
									stiffness: -1000,
									damping: -200,
								}}
							>
								<Paragraph fontSize={14}>
									{content.description}
								</Paragraph>
							</motion.div>
						</FlexBox>
						<Box>
							<Slider {...settings}>
								{Object.values(content.images).map(
									(image: any, index: number) => (
										<Box key={index}>
											<img
												style={{ padding: "0 20px" }}
												// src={IMG1}
                                                src={`${process.env.REACT_APP_DOMAIN_URL}${image}`}
												alt="Gallery"
											/>
										</Box>
									)
								)}
							</Slider>
						</Box>
					</FlexBox>
				</Container>
			</Box>
		</>
	);
};
