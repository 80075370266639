import React, { useEffect, useState } from "react";
import MapPageLayout from "../MapPageLayout";
import { Breadcrumb, Empty } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { FlexBox } from "components/StyledComponents";
import { filterMarkers, getMarkers, getNoTripTourList } from "services/map";
import { IScene } from "types";
import { UseAppDispatch } from "hooks/hooks";
import { addScenesData } from "store/slices/mapSlice";
import ScenesSkeleton from "components/skeleton/ScenesSkeleton";
import SceneElement from "components/common/SceneElement";
import SceneDetail from "../SceneDetail";
import EmptyData from "components/common/EmptyData";

import "pages/map/style.scss";

interface IBreadCrumb {
	title: string | JSX.Element;
	key: string;
}

interface IFilterReq {
	city: string;
	trip: string;
	type: string;
	date: string;
}

const Filter = () => {
	const brdeacrumbItems: IBreadCrumb[] = [
		{ title: <Link to="/mark-location">Trips</Link>, key: "trips" },
		{ title: "Filters", key: "filter" },
	];

	const dispatch = UseAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [filteredScenes, setFilteredScenes] = useState<Array<IScene>>([]);

	const [isSceneDetailOpen, setIsSceneDetailOpen] = useState<boolean | null>(
		null
	);
	const [currentScene, setCurrentScene] = useState<string>("");

	useEffect(() => {
		const sceneId = searchParams.get("scene_id");
		if(sceneId){
			setCurrentScene(sceneId);
			setIsSceneDetailOpen(true);
		}
	}, []);

	useEffect(() => {
		const filterReqData = {
			city: searchParams.get("city") || "",
			trip: searchParams.get("trip") || "",
			type: searchParams.get("type") || "",
			date: searchParams.get("date") || "",
		};
		const sceneId = searchParams.get("scene_id");
		if(!sceneId){
			getFilterScenes(filterReqData);
			setIsSceneDetailOpen(false);
		}
	}, [searchParams]);

	const handleSceneClick = (sceneId: string) => {
		searchParams.set("scene_id", sceneId);
		setSearchParams(searchParams);
		setCurrentScene(sceneId);
		setIsSceneDetailOpen(true);
	};

	const sceneElement = filteredScenes.map((scene: IScene, index: number) => (
		<SceneElement
			scene={scene}
			key={index}
			handleSceneClick={handleSceneClick}
		/>
	));

	// API CALL

	const getFilterScenes = async (filterReqData: IFilterReq) => {
		setIsLoading(true);
		try {
			const response: any = await filterMarkers(filterReqData);
			setFilteredScenes(response.data ? response.data : []);
			dispatch(addScenesData(response.data ? response.data : []));
		} catch (error) {
			console.log(error);
			setFilteredScenes([]);
			dispatch(addScenesData([]));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<MapPageLayout>
			<>
				{isSceneDetailOpen ? (
					<SceneDetail id={currentScene} />
				) : (
					<>
						<Breadcrumb
							className="tabs-breadcrumb"
							separator=">"
							items={brdeacrumbItems}
						/>
						<FlexBox className="scene-box">
							{isLoading ? <ScenesSkeleton /> : sceneElement}
						</FlexBox>
						{!isLoading && filteredScenes.length === 0 && (
							<EmptyData message="No data found. Please try searching something else." />
						)}
					</>
				)}
			</>
		</MapPageLayout>
	);
};

export default Filter;
