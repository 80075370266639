import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col, Input, Form } from "antd";
import {
	Box,
	Container,
	FlexBox,
	Paragraph,
	Title,
} from "components/StyledComponents";
import { notifications } from "components/notifications";
import { DEFAULT_ERROR_MSG } from "constants/Common";
import { subscribeUserEmail } from "services/pages";

import LOGO from "assets/images/logo.png";

interface IProps {
	socialLinks: any;
}

const MainFooter = ({ socialLinks }: IProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onFinish = (values: { email: string }) => {
		subscribeEmail(values);
	};

	// Call API

	const subscribeEmail = async (userData: { email: string }) => {
		setIsLoading(true);
		try {
			await subscribeUserEmail(userData);
			notifications.SUCCESS("ERROR", "You have successfully subscribed");
		} catch (error: any) {
			// console.log("error.response.data.errors >>", error.response.data.errors);
			notifications.ERROR(
				"ERROR",
				error.response.data.errors ||
					error.response.data.message ||
					DEFAULT_ERROR_MSG
			);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box className="footer-top">
			<Container>
				<Row gutter={{ md: 64 }}>
					<Col lg={8}>
						<FlexBox gap={20}>
							{/* <Title level={1}>LOGO</Title> */}
							{/* <Box className="logo"> */}
							<Link to="/" style={{ maxWidth: 150 }}>
								<img src={LOGO} alt="Logo" />
							</Link>
							{/* </Box> */}
							<Paragraph fontSize={14}>
								{socialLinks?.footer_site_description}
							</Paragraph>
						</FlexBox>
					</Col>
					<Col lg={8}>
						<FlexBox gap={16}>
							<Title level={3}>Site Links</Title>
							<FlexBox gap={4}>
								<Link to="/">Home</Link>
								{/* <Link to="/">Blog</Link> */}
								{/* <Link to="/">Group Tracking</Link> */}
								<Link to="/terms-conditions">Terms</Link>
								<Link to="/privacy-policy">Privacy Policy</Link>
							</FlexBox>
						</FlexBox>
					</Col>
					<Col lg={8}>
						<FlexBox gap={16}>
							<Title level={3}>Subscribe Us</Title>
							<FlexBox gap={12}>
								<Paragraph fontSize={14}>
									{socialLinks?.subscribe_us}
								</Paragraph>
								<Form
									name="basic"
									layout="vertical"
									initialValues={{
										email: "",
									}}
									onFinish={onFinish}
									autoComplete="off"
								>
									{/* <FlexBox
										flexDirection="row"
										gap={8}
										className="footer-subscribe"
									> */}
									<Form.Item
										name="email"
										rules={[
											{
												required: true,
												message:
													"Please input your email!",
											},
											{
												type: "email",
												message: "Invalid email format",
											},
										]}
									>
										<Input placeholder="Email" />
									</Form.Item>
									<Form.Item
										style={{
											marginBottom: 0,
											textAlign: "center",
										}}
									>
										<Button
											type="primary"
											htmlType="submit"
											block
											className="round-btn"
											loading={isLoading}
											disabled={isLoading}
										>
											Subscribe
										</Button>
									</Form.Item>
									{/* </FlexBox> */}
								</Form>
							</FlexBox>
						</FlexBox>
					</Col>
				</Row>
			</Container>
		</Box>
	);
};

export default MainFooter;
