import { AxiosResponse } from "axios";
import { baseApi } from ".";

export const addMarker = (data: FormData) => {
    return baseApi.post("mark-location", data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
};
export const getTripList = () => baseApi.get("get-trip-list");
export const getTourList = (tripId: string) => baseApi.get(`trip/${tripId}/scenes`);
export const getNoTripTourList = () => baseApi.get(`scenes-without-trip`);
export const allMarkers = () => baseApi.get(`markers`);

export const getTourDetail = (tourId: string) => baseApi.get(`scene/show/${tourId}`);
export const uploadTourGallery = (sceneId: string, fileData: any) => {
    return baseApi.post(`scene/upload-gallery/${sceneId}`, fileData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
};

export const updateTrip = (tripId: string, data: any) => {
    return baseApi.post(`trip/update/${tripId}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
};

export const updateScene = (sceneId: string, data: any) => {
    return baseApi.post(`scene/${sceneId}/update`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
};

export const deleteGallery = (sceneId: string, imageIndex: number) => baseApi.get(`scene/${sceneId}/gallery-image/${imageIndex}/delete`);

export const getMarkers = () => baseApi.get(`get-markers`);
export const filterMarkers = (filterData?: {city: string, trip: string, type: string, date: string}) => baseApi.get(`filter/scenes?city=${filterData?.city || ''}&trip=${filterData?.trip || ''}&type=${filterData?.type || ''}&date=${filterData?.date || ''}`);
export const getTour = (data: {marker: string}) => baseApi.post("get-my-tour", data);
export const getCities = () => baseApi.get("user/cities");

export const deleteTrip = (tripId: string) => baseApi.get(`trip/${tripId}/delete`);
export const deleteTour = (sceneId: number) => baseApi.get(`scene/${sceneId}/delete`);

