import React, { useCallback, useEffect, useState } from "react";
import { Box, Paragraph } from "components/StyledComponents";
import { useGoogleLogin } from "@react-oauth/google";
import axios, { AxiosResponse } from "axios";
import { googleLogin } from "services/auth";
import { addAuthData } from "store/slices/authSlice";
import { useDispatch } from "react-redux";
import { notifications } from "components/notifications";
import { DEFAULT_ERROR_MSG } from "constants/index";

// import {
// 	LoginSocialGoogle,
// 	LoginSocialAmazon,
// 	LoginSocialFacebook,
// 	LoginSocialGithub,
// 	LoginSocialInstagram,
// 	LoginSocialLinkedin,
// 	LoginSocialMicrosoft,
// 	LoginSocialPinterest,
// 	LoginSocialTwitter,
// 	LoginSocialApple,
// 	IResolveParams,
// } from "reactjs-social-login";

// import {
// 	FacebookLoginButton,
// 	GoogleLoginButton,
// 	GithubLoginButton,
// 	AmazonLoginButton,
// 	InstagramLoginButton,
// 	LinkedInLoginButton,
// 	MicrosoftLoginButton,
// 	TwitterLoginButton,
// 	AppleLoginButton,
// } from "react-social-login-buttons";

interface IProps {
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SocialLogin = ({ setIsLoading }: IProps): JSX.Element => {
	const dispatch = useDispatch();

	const [user, setUser] = useState<any>(null);

	const handleGoogleLogin = useGoogleLogin({
		onSuccess: (codeResponse) => setUser(codeResponse),
		onError: (error) => console.log("Login Failed:", error),
	});

	useEffect(() => {
		if (user) {
			axios
				.get(
					`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
					{
						headers: {
							Authorization: `Bearer ${user.access_token}`,
							Accept: "application/json",
						},
					}
				)
				.then((res) => {
					// setProfile(res.data);
					setUser(null);
					// googleLoginAPI({name: res.data.name, email: res.data.email})
					// dispatch(authGoogle({ name: res.data.name, email: res.data.email }))
					googleLoginAPI({
						name: res.data.name,
						email: res.data.email,
					});
					// console.log("res.data >>", res.data);
				})
				.catch((err) => console.log(err));
		}
	}, [user]);

	// API CALL

	const googleLoginAPI = async (data: { name: string; email: string }) => {
		setIsLoading(true);
		try {
			const response: AxiosResponse<any> = await googleLogin(data);
			const authData = {
				user: {
					fullname: response.data.fullname,
					user_email: response.data.user_email,
					isNewUser: response.data.isNewUser,
					totalTrips: response.data.totalTrips,
				},
				token: response.data.token,
			};
			dispatch(addAuthData(authData));
		} catch (error: any) {
			notifications.ERROR(
				"Error",
				error.response.data.message || DEFAULT_ERROR_MSG
			);
		} finally {
			setIsLoading(false);
		}
	};

	// ---------------------

    // const REDIRECT_URI = 'http://localhost:3000/login';

	// const [provider, setProvider] = useState("");
	// const [profile, setProfile] = useState<any>();

	// const onLoginStart = useCallback(() => {
	// 	alert("login start");
	// }, []);

	// const onLogoutSuccess = useCallback(() => {
	// 	setProfile(null);
	// 	setProvider("");
	// 	alert("logout success");
	// }, []);

	return (
		<>
			<Box className="google-btn" onClick={() => handleGoogleLogin()}>
				<Box className="google-icon-wrapper">
					<img
						className="google-icon"
						src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
						alt="Google Login"
					/>
				</Box>
				<Paragraph className="btn-text">Sign in with google</Paragraph>
			</Box>

			{/* <LoginSocialFacebook
				appId={'774358801139679'}
				fieldsProfile={
					"id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
				}
				onLoginStart={onLoginStart}
				onLogoutSuccess={onLogoutSuccess}
				redirect_uri={REDIRECT_URI}
				onResolve={({ provider, data }: IResolveParams) => {
					setProvider(provider);
					setProfile(data);
				}}
				onReject={(err) => {
					console.log(err);
				}}
			>
				<FacebookLoginButton />
			</LoginSocialFacebook> */}
		</>
	);
};

export default SocialLogin;
