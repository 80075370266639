import styled from "styled-components";

import {
	border,
	BorderProps,
	color,
	ColorProps,
	compose,
	flexbox,
	FlexboxProps,
	grid,
	GridProps,
	layout,
	LayoutProps,
	shadow,
	ShadowProps,
	space,
	SpaceProps,
	typography,
	TypographyProps,
} from "styled-system";
import { Typography } from "antd";

type TBoxProps = BorderProps &
	ColorProps &
	FlexboxProps &
	GridProps &
	LayoutProps &
	ShadowProps &
	SpaceProps &
	TypographyProps;
type TTypographyProps = SpaceProps;
type TParagraphProps = ColorProps & SpaceProps & TypographyProps;

export const Box = styled.div<TBoxProps>`
	${compose(border, color, flexbox, grid, layout, shadow, space, typography)}
`;

export const FlexBox = styled(Box)<{ gap?: number; flexDirection?: string }>`
	display: flex;
	flex-direction: ${(props) => props.flexDirection || "column"};
	align-items: ${(props) => props.flexDirection && props.flexDirection === "row" ? "center" : "unset"};
	gap: ${(props) => props.gap || 0}px;
`;

export const Container = styled.div`
	width: 1140px;
	margin: auto;
	@media (max-width: 1200px) {
		width: 90%;
	}
`;

export const Title = styled(Typography.Title)<TTypographyProps>`
	${space};
	margin-top: 0 !important;
	margin-bottom: 0 !important;
`;

export const Paragraph = styled.p<TParagraphProps>`
	margin: 0;
	font-size: 16px;
	color: #888888;
	font-weight: 500;
	line-height: 1.5;
	${compose(color, space, typography)}
`;

export const Divider = styled.div<{ size?: number }>`
	display: flex;
	clear: both;
	width: 100%;
	min-width: 100%;
	margin: ${(props) => `${props.size}` || 16}px 0;
	line-height: 1.5714285714285714;
	border-block-start: 1px solid #cccccc;
`;