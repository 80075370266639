import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

import {
	Drawer,
	Button,
	Form,
	Input,
	Select,
	DatePicker,
	Tour,
	TourProps,
	DatePickerProps,
} from "antd";
import type { SelectProps, UploadFile } from "antd";
import { EnvironmentFilled } from "@ant-design/icons";

import MultiImages from "components/upload/MultiImages";
import { Box, FlexBox, Paragraph } from "components/StyledComponents";
import { MARKER_TYPES } from "constants/Map";
import { getTripList } from "services/map";

import { ITrip } from "types/Map";
import { UseAppDispatch, UseAppSelector } from "hooks/hooks";

import "components/drawers/style.scss";
import { updateTourStatus } from "services/auth";
import { updateTourStatusAuth } from "store/slices/authSlice";

const { TextArea } = Input;

interface IProps {
	drawerOpen: boolean;
	setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
	lat: string;
	lng: string;
	handleMarkerSubmit: (formData: FormData) => Promise<void>;
}

interface IMarkerForm {
	trip: string;
	name: string;
	description: string;
	type: string;
	date: string;
}

const CreateScenerDrawer = ({
	drawerOpen,
	setDrawerOpen,
	lat,
	lng,
	handleMarkerSubmit,
}: IProps) => {
	const dispatch = UseAppDispatch();

	const [form] = Form.useForm();

	const markerColor = UseAppSelector((state) => state.map.newMarkerColor);
	const address = UseAppSelector((state) => state.map.newAutoCompleteAddress);
	const city = UseAppSelector((state) => state.map.markerCity);
	const userData = UseAppSelector((state) => state.auth.user);

	const ref1 = useRef(null);
	const ref2 = useRef(null);

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [galleryImage, setGalleryImage] = useState<UploadFile[]>();
	const [options, setOptions] = useState<SelectProps["options"]>([]);
	const [visitDate, setVisitDate] = useState<string>("");

	const [open, setOpen] = useState<boolean>(false);
	const [selectOpen, setSelectOpen] = useState(false);

	useEffect(() => {
		if (drawerOpen) {
			form.resetFields();
			setTimeout(() => {
				setOpen(userData?.tourStatus === 0 ? true : false);
			}, 500);
			fetchTrips();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [drawerOpen]);

	const onFinish = async (values: IMarkerForm) => {
		const formData = new FormData();
		values.trip &&
			values.trip.length > 0 &&
			formData.append("trip", values.trip[0]);
		formData.append("name", values.name);
		formData.append("latitude", lat);
		formData.append("longitude", lng);
		formData.append("marker_color", markerColor);
		formData.append("address", address);
		formData.append("city", city);
		formData.append("description", values.description);
		formData.append("visit_date", moment(visitDate).format("YYYY-MM-DD"));
		formData.append("type", values.type);
		galleryImage &&
			galleryImage.forEach((image: UploadFile<Blob>, index: number) => {
				formData.append("gallery[]", image as any);
			});
		console.log("galleryImage >>", formData, galleryImage);
		setIsSubmitting(true);
		await handleMarkerSubmit(formData);
		setIsSubmitting(false);
	};

	const handleGalleryImage = async (fileData: UploadFile[]) => {
		setGalleryImage(fileData);
	};

	const handleChange = (values: Array<string>) => {
		const newArr = values.length === 0 ? [] : [values.slice(-1)[0]];
		form.setFieldsValue({ trip: newArr });
		setSelectOpen(false);
	};

	const onChange: DatePickerProps["onChange"] = (date, dateString) => {
		// console.log(date, dateString);
		setVisitDate(dateString)
	};

	const steps: TourProps["steps"] = [
		{
			title: "Address",
			description: "The address you choose",
			placement: "right",
			target: () => ref1.current,
		},
		{
			title: "Submit",
			description:
				"Once you fill all require data, submit it to create a new Scene.",
			placement: "right",
			target: () => ref2.current,
		},
	];

	const handleClose = () => {
		setOpen(false);
		updateStatusTour();
	};

	// API CALL

	const fetchTrips = async () => {
		try {
			const response: any = await getTripList();
			const optionsData =
				response.data &&
				response.data.map((data: ITrip) => {
					return {
						label: data.name,
						value: data.slug,
					};
				});
			setOptions(optionsData || []);
			// console.log("response >>", response);
		} catch (error) {
			console.log(error);
		} finally {
			console.log("finished");
		}
	};

	const updateStatusTour = async () => {
		try {
			await updateTourStatus({
				tour_status: (userData?.tourStatus || 0) + 1,
			});
			dispatch(updateTourStatusAuth((userData?.tourStatus || 0) + 1));
		} catch (error) {
			console.log(error);
		} finally {
			console.log("finished");
		}
	};

	return (
		<>
			<Drawer
				destroyOnClose
				title={"Mark your location"}
				placement="right"
				onClose={() => setDrawerOpen(false)}
				open={drawerOpen}
				rootClassName="create-scene-drawer-root"
				className="create-scene-drawer"
			>
				<Box className="create-scene-drawer-main-content">
					<Box ref={ref1}>
						<FlexBox gap={8} flexDirection={"row"} marginBottom={2}>
							<EnvironmentFilled
								style={{ fontSize: 18 }}
								className="svg-primary"
							/>
							<Paragraph fontSize={14}>
								{/* 368-1863 512 Jumano Ct Suffern, New York(NY), 10901 */}
								{address ||
									"368-1863 512 Jumano Ct Suffern, New York(NY), 10901"}
							</Paragraph>
						</FlexBox>
					</Box>
					<Paragraph fontSize={14}>
						Please fill all required data to create a new scene.
					</Paragraph>
				</Box>
				<Form
					form={form}
					name="basic"
					style={{ maxWidth: 600 }}
					layout="vertical"
					initialValues={{}}
					onFinish={onFinish}
					autoComplete="off"
				>
					<Form.Item label="Trip" name="trip">
						<Select
							mode="tags"
							open={selectOpen}
							onDropdownVisibleChange={(visible) =>
								setSelectOpen(visible)
							}
							style={{ width: "100%" }}
							placeholder="Choose trip"
							onChange={handleChange}
							options={options}
						/>
					</Form.Item>

					<Form.Item
						label="Scene Name"
						name="name"
						rules={[
							{
								required: true,
								message: "Please input your Scene Name!",
							},
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label="Scene Description"
						name="description"
						rules={[
							{
								required: true,
								message: "Please input your description!",
							},
						]}
					>
						<TextArea rows={4} />
					</Form.Item>

					<Form.Item
						label="Category"
						name="type"
						rules={[
							{
								required: true,
								message: "Please input your type!",
							},
						]}
					>
						<Select
							style={{ width: "100%" }}
							placeholder="Choose trip"
							options={MARKER_TYPES}
						/>
					</Form.Item>

					<Form.Item
						label="Date"
						name="date"
						rules={[
							{
								required: true,
								message: "Please input your date!",
							},
						]}
					>
						<DatePicker onChange={onChange} style={{ width: "100%" }} />
					</Form.Item>

					<Form.Item label="Gallery">
						<MultiImages
							title={"Upload Profile Picture"}
							uploadFunction={handleGalleryImage}
						/>
					</Form.Item>

					<Form.Item>
						<Button
							ref={ref2}
							type="primary"
							htmlType="submit"
							className="btn-primary"
							loading={isSubmitting}
							disabled={isSubmitting}
						>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Drawer>

			<Tour open={open} onClose={handleClose} steps={steps} />
		</>
	);
};

export default CreateScenerDrawer;
