import React, { useEffect, useRef, useState } from "react";
import {
	GoogleMap as GoogleMapComp,
	LoadScript,
	Marker,
	MarkerClusterer,
	Polyline,
	Autocomplete,
	InfoWindow,
} from "@react-google-maps/api";
import { EnvironmentFilled } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { MapStyle } from "./MapStyle";
import { UseAppDispatch, UseAppSelector } from "hooks/hooks";
import { IScene } from "types";

import { COLOR_CODES } from "constants/Map";
import { FlexBox, Paragraph } from "components/StyledComponents";
import {
	addNewAutoCompleteAddress,
	addNewMarkerCity,
	addNewMarkerColor,
} from "store/slices/mapSlice";
import axios from "axios";

interface IProps {
	drawerOpen: boolean;
	setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setLat: React.Dispatch<React.SetStateAction<string>>;
	setLng: React.Dispatch<React.SetStateAction<string>>;
	zoom?: number;
}

const DEFAULT_CENTER = { lat: 25.837232487634086, lng: 70.43596434818491 };

const LIBRARIES: (
	| "geometry"
	| "places"
	| "drawing"
	| "localContext"
	| "visualization"
)[] = ["places"];

const GoogleMap = ({
	drawerOpen,
	setDrawerOpen,
	setLat,
	setLng,
	zoom = 3,
}: IProps) => {

	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
	const isLaptop = useMediaQuery({ query: "(max-width: 1224px)" });
	const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 580px)" });

	const navigate = useNavigate();
	const location = useLocation();

	const dispatch = UseAppDispatch();

	const filterRoutes = ["", "?tab=trips"];

	const IS_POLYLINE_VISIBLE =
		location.pathname === "/mark-location" &&
		filterRoutes.includes(location.search)
			? false
			: true;

	const [mapref, setMapRef] = useState<any>(null);
	const [positions, setPositions] = useState<any>(null);
	const [addPositions, setAddPositions] = useState<any>(null);
	const [currentActiveScene, setCurrentActiveScene] = useState<any>(null);
	const [currentActiveCluster, setCurrentActiveCluster] = useState<any>(null);
	const [currentAddress, setCurrentAddress] = useState<any>(null);

	const autoCompleteInput = useRef<any>();

	const [activeCenter, setActiveCenter] = useState<{
		lat: number;
		lng: number;
	}>(DEFAULT_CENTER);

	const scenesData: Array<IScene> | null = UseAppSelector(
		(state) => state.map.sceneData
	);

	useEffect(() => {
		scenesData && setPositions(scenesData);
		if (scenesData) {
			const coordinates: { lat: Array<number>; lng: Array<number> } = {
				lat: [],
				lng: [],
			};

			scenesData.map((l: IScene) => {
				coordinates.lat.push(parseFloat(l.lat));
				coordinates.lng.push(parseFloat(l.long));
			});

			const averageLat =
				(Math.max(...coordinates.lat) + Math.min(...coordinates.lat)) /
				2;
			const averageLng =
				(Math.max(...coordinates.lng) + Math.min(...coordinates.lng)) /
				2;

			// setAverageLngLng({ lat: averageLat, lng: averageLng });
			setActiveCenter({ lat: averageLat, lng: averageLng });
			if (autoCompleteInput.current) {
				autoCompleteInput.current.value = "";
			}
		}
	}, [scenesData]);

	useEffect(() => {
		if (!drawerOpen) {
			setAddPositions(null);
			if (autoCompleteInput.current) {
				autoCompleteInput.current.value = "";
			}
		}
	}, [drawerOpen]);

	const containerStyle = {
		width: "100%",
		height: "calc(100vh - 136px)",
	};

	const onLoad = (polyline: any) => {
		console.log("polyline: ", polyline);
	};

	const polyLines =
		positions &&
		positions.map((position: any) => {
			return {
				lat: parseFloat(position.lat),
				lng: parseFloat(position.long),
			};
		});

	const polylineOptions = {
		strokeColor: "#532c87",
		strokeOpacity: 0.8,
		strokeWeight: 1,
		fillColor: "#532c87",
		fillOpacity: 0.35,
		clickable: false,
		draggable: false,
		editable: false,
		visible: true,
		radius: 1,
		zIndex: 1,
		paths: polyLines,
	};

	const mapIcon = (marker_color: string, index: number) => {
		const random = Math.floor(Math.random() * COLOR_CODES.length);
		const color = COLOR_CODES[random];
		dispatch(addNewMarkerColor(color));
		return {
			path: "M256,0C160.798,0,83.644,77.155,83.644,172.356c0,97.162,48.158,117.862,101.386,182.495 C248.696,432.161,256,512,256,512s7.304-79.839,70.97-157.148c53.228-64.634,101.386-85.334,101.386-182.495 C428.356,77.155,351.202,0,256,0z M256,231.921c-32.897,0-59.564-26.668-59.564-59.564s26.668-59.564,59.564-59.564 c32.896,0,59.564,26.668,59.564,59.564S288.896,231.921,256,231.921z",
			fillColor: marker_color || color,
			fillOpacity: 0.9,
			scale: 0.07,
			strokeColor: marker_color || color,
			strokeWeight: 2,
			anchor: new window.google.maps.Point(250, 540),
			origin: new window.google.maps.Point(0, 0),
			scaledSize: new window.google.maps.Size(40, 40),
		};
	};

	const onMapClick = async (ev: any) => {
		// console.log("ev>", ev.latLng.lat(), ev.latLng.lng())
		setAddressPosition(ev.latLng.lat(), ev.latLng.lng());
		await getAddressFromLatLng(ev.latLng.lat(), ev.latLng.lng());
		setDrawerOpen(true);
	};

	const setAddressPosition = (lat: string, lng: string) => {
		setLat(lat);
		setLng(lng);
		setAddPositions({
			created_at: "",
			id: 1,
			images: [],
			lat: lat,
			long: lng,
			marker_image: "",
			marker_name: "",
			tripId: 1,
			updated_at: "",
			userId: "",
		});
	};

	const handleInfoWindowOpen = (scene: IScene) => {
		setCurrentActiveScene(scene);
	};

	const handleInfoWindowClose = () => {
		setCurrentActiveScene(null);
	};

	const createKey = (location: any) => {
		return location.lat + location.long;
	};

	const onMarkerClick = (sceneId: number) => {
		navigate(`/mark-location/scene-detail/${sceneId}`);
	};

	const handleMapOnLoad = (map: any) => {
		setMapRef(map);
	};

	const hadnleCenterChange = () => {
		if (mapref) {
			const newCenter = mapref.getCenter();
		}
	};

	const infoWindowData = currentActiveScene || currentActiveCluster || null;

	// let autocomplete: any = null;

	const handleOnLoad = (address: any) => {
		// autocomplete = address;
		setCurrentAddress(address);
	};

	const handleAutoCompleteChange = () => {
		// console.log("autoComplete >", currentAddress.getPlace(), currentAddress.getPlace().geometry.location.lat(), currentAddress.getPlace().geometry.location.lng());
		const lat = currentAddress.getPlace().geometry.location.lat() || "";
		const lng = currentAddress.getPlace().geometry.location.lng() || "";
		const address = autoCompleteInput?.current?.value;
		const city =
			currentAddress
				.getPlace()
				.address_components.find((addr: any) =>
					addr.types.includes("locality")
				)?.long_name ||
			currentAddress
				.getPlace()
				.address_components.find((addr: any) =>
					addr.types.includes("administrative_area_level_3")
				)?.long_name ||
			currentAddress
				.getPlace()
				.address_components.find((addr: any) =>
					addr.types.includes("administrative_area_level_2")
				)?.long_name ||
			currentAddress
				.getPlace()
				.address_components.find((addr: any) =>
					addr.types.includes("administrative_area_level_1")
				)?.long_name ||
			"";
		setActiveCenter({ lat: lat, lng: lng });
		dispatch(addNewAutoCompleteAddress(address));
		dispatch(addNewMarkerCity(city));

		setAddressPosition(lat, lng);
		setDrawerOpen(true);
	};

	const getAddressFromLatLng = async (lat: string, lng: string) => {
		try {
			const response = await axios.get(
				`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
			);

			console.log("response >>", response);

			const city =
				response.data.results[0].address_components.find((addr: any) =>
					addr.types.includes("locality")
				)?.long_name ||
				response.data.results[0].address_components.find((addr: any) =>
					addr.types.includes("administrative_area_level_3")
				)?.long_name ||
				response.data.results[0].address_components.find((addr: any) =>
					addr.types.includes("administrative_area_level_2")
				)?.long_name ||
				response.data.results[0].address_components.find((addr: any) =>
					addr.types.includes("administrative_area_level_1")
				)?.long_name ||
				"";
			
			const fullAddressArr = (response.data.results[0].length > 1 ? response.data.results[0] : response.data.results[1]).address_components.filter((addr: any) => !addr.types.includes("plus_code"));
			const fullAddress = fullAddressArr.map((item: any) => item.long_name).join(" ");
			console.log("fullAddress >>", fullAddressArr, fullAddress);

			dispatch(
				addNewAutoCompleteAddress(
					// response.data.results[0].formatted_address
					fullAddress
				)
			);
			dispatch(addNewMarkerCity(city));
		} catch (e) {
			console.log("e");
		}
	};

	return (
		<>
			
			<LoadScript
				googleMapsApiKey={
					process.env.REACT_APP_GOOGLE_API_KEY as string
				}
				// googleMapsApiKey="AIzaSyAyPPy9CsiKrNwt2-yZuEOCQaYbMHdVc2M"
				// googleMapsApiKey="AIzaSyB9xGuYF_hfljucbtVCY1OdLVO5vDkr8f8"
				libraries={LIBRARIES}
			>
				<GoogleMapComp
					mapContainerStyle={containerStyle}
					center={activeCenter}
					zoom={zoom}
					onClick={(ev) => onMapClick(ev)}
					options={{
						styles: MapStyle,
						mapId: "f0c2f20f1a027bf0",
					}}
					onLoad={handleMapOnLoad}
					onCenterChanged={hadnleCenterChange}
				>
					{/* Auto Complete */}
					<Autocomplete
						onLoad={handleOnLoad}
						onPlaceChanged={handleAutoCompleteChange}
					>
						<input
							type="text"
							placeholder="Search"
							ref={autoCompleteInput}
							// onChange={handleAutoCompleteChange}
							style={{
								boxSizing: `border-box`,
								border: `1px solid transparent`,
								width: (isMobile || isTablet) ? `calc(100% - 120px)` : `360px`,
								height: `48px`,
								padding: `0 16px`,
								borderRadius: `3px`,
								boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
								fontSize: `14px`,
								outline: `none`,
								textOverflow: `ellipses`,
								position: "absolute",
								left: "10px",
								top: "10px",
								// marginLeft: "-120px"
							}}
						/>
					</Autocomplete>
					{/* Info Window */}
					{infoWindowData && (
						<InfoWindow
							onLoad={onLoad}
							position={{
								lat: parseFloat(infoWindowData.lat),
								lng: parseFloat(infoWindowData.long),
							}}
						>
							<FlexBox
								minWidth={200}
								gap={4}
								alignItems={"start !important"}
							>
								<Paragraph
									className="color-black"
									fontWeight={600}
									fontSize={14}
								>
									{infoWindowData?.get_trip_data?.name || ""}
								</Paragraph>
								<FlexBox gap={8} flexDirection={"row"}>
									<EnvironmentFilled className="svg-primary" />
									<Paragraph fontSize={12}>
										{infoWindowData?.marker_name ||
											"Marker Name Here"}
									</Paragraph>
								</FlexBox>
								{/* <Button type="primary" className="sm-btn" style={{ fontSize: 10, padding: '4px 12px', minHeight: 24, height: 24, marginTop: 4 }}>View Details</Button> */}
							</FlexBox>
						</InfoWindow>
					)}
					<MarkerClusterer
					// onMouseOver={(cluster) => handleOpenInfoWindowCluster(cluster)}
					>
						{(clusterer) => {
							const renderPositions = addPositions
								? [addPositions]
								: positions || [];
							return (
								renderPositions &&
								renderPositions.map(
									(scene: IScene, index: number) => {
										const markerObj = {
											key: index + 1,
											name: scene.marker_name,
											lat: parseFloat(scene.lat),
											lng: parseFloat(scene.long),
											markerImg: scene.marker_image,
										};
										return (
											<Marker
												icon={mapIcon(
													scene.marker_color,
													addPositions
														? (positions || [])
																.length + 1
														: index
												)}
												key={createKey(markerObj)}
												position={markerObj}
												onClick={() =>
													onMarkerClick(scene.id)
												}
												clusterer={clusterer}
												onMouseOver={() =>
													handleInfoWindowOpen(scene)
												}
												onMouseOut={
													handleInfoWindowClose
												}
											/>
										);
									}
								)
							);
						}}
					</MarkerClusterer>

					{polyLines && IS_POLYLINE_VISIBLE && (
						<Polyline
							onLoad={onLoad}
							path={polyLines}
							options={polylineOptions}
						/>
					)}

					{/* {polyLines &&
						polyLines.map((location: any, index: number) => (
							<Circle
								key={index}
								center={{
									lat: location.lat,
									lng: location.lng,
								}}
								options={{
									...circleOptions,
									radius: 105000,
									strokeColor:
										COLOR_CODES[
											Math.floor(
												Math.random() *
													COLOR_CODES.length
											)
										],
									fillColor:
										COLOR_CODES[
											Math.floor(
												Math.random() *
													COLOR_CODES.length
											)
										],
								}}
							/>
						))} */}
				</GoogleMapComp>
			</LoadScript>
		</>
	);
};

export default GoogleMap;
