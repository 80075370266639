import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Avatar, TourProps, Tour } from "antd";
import { useMediaQuery } from "react-responsive";

import { Box, Divider, FlexBox, Paragraph } from "components/StyledComponents";

import {
	ArrowLeftOutlined,
	ArrowRightOutlined,
	ArrowDownOutlined,
	ArrowUpOutlined,
	FilterOutlined
} from "@ant-design/icons";

import CreateScenerDrawer from "components/drawers/CreateScenerDrawer";
import { UseAppDispatch, UseAppSelector } from "hooks/hooks";
import NewUserModal from "components/modals/NewUserModal";
import { addMarker } from "services/map";
import GoogleMap from "./googleMap";

import {
	updateNewUserKey,
	updateTotalTrips,
	updateTourStatusAuth,
} from "store/slices/authSlice";
import {
	collapseTrue,
	toggleCollapse,
	updateRenderKey,
} from "store/slices/mapSlice";

import { notifications } from "components/notifications";

import FilterForm from "./filter/FilterForm";

import "pages/map/style.scss";
import { IScene } from "types";
import { updateTourStatus } from "services/auth";

interface IProps {
	children: JSX.Element | JSX.Element[];
	zoom?: number;
	openSidePanel?: boolean;
}

const MapPageLayout = ({
	children,
	zoom = 3,
	openSidePanel = false,
}: IProps): JSX.Element => {
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
	const isLaptop = useMediaQuery({ query: "(max-width: 1224px)" });
	const isTablet = useMediaQuery({ query: "(max-width: 768px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

	const dispatch = UseAppDispatch();

	const ref1 = useRef(null);

	const isHideWelcomePopup = UseAppSelector(
		(state) => state.auth.hideWelcomePopup
	);
	const user = UseAppSelector((state) => state.auth.user);
	const isCollapse = UseAppSelector((state) => state.map.isCollapse);
	const isNewUser = user?.isNewUser;

	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const [lat, setLat] = useState<string>("");
	const [lng, setLng] = useState<string>("");
	const [open, setOpen] = useState<boolean>(false);
	const [filterHeight, setFilterHeight] = useState<number>(isTablet ? 0 : 56);

	const isCollapseBtnStyle =
		isMobile || isTablet
			? { top: "calc(100vh - 136px)", left: "calc(50% - 30px)" }
			: { right: isCollapse ? "calc(33.33% + 10px)" : "10px" };

	const scenesData: Array<IScene> | null = UseAppSelector(
		(state) => state.map.sceneData
	);

	const handleToggleDrawer = () => {
		// setIsCollapse(!isCollapse);
		dispatch(toggleCollapse());
	};

	useEffect(() => {
		scenesData &&
			scenesData.length > 0 &&
			user?.tourStatus === 1 &&
			setOpen(true);
	}, [scenesData]);

	useEffect(() => {
		openSidePanel && dispatch(collapseTrue());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openSidePanel]);

	const mapSectionCol = isNewUser || !isCollapse ? 24 : 16;
	const contentSectionCol =
		isNewUser || !isCollapse ? 0 : isMobile || isTablet ? 24 : 8;

	const steps: TourProps["steps"] = [
		{
			title: "Your Markers",
			description: "You can check all markers on google map",
			target: null,
		},
		{
			title: "Trips",
			description:
				"Click on button will open the sidedrawer to check trips",
			// target: () => ref2.current,
			placement: "right",
			target: () => ref1.current,
			nextButtonProps: {
				children: "Open",
				onClick: handleToggleDrawer,
			},
		},
	];

	const handleClose = () => {
		setOpen(false);
		updateStatusTour();
	};

	const handleFilterHeight = () => {
		setFilterHeight(filterHeight === 0 ? 96 : 0);
	};

	// CALL API

	const handleMarkerSubmit = async (formData: FormData) => {
		try {
			const response = await addMarker(formData);
			isNewUser === 1 && dispatch(updateNewUserKey(0));
			notifications.SUCCESS("SUCCESS", "Your scene has been created");
			dispatch(updateRenderKey());
			dispatch(updateTotalTrips(response.data));
			// getAllMarkers();
		} catch (error) {
			console.log(error);
		} finally {
			setDrawerOpen(false);
		}
	};

	const updateStatusTour = async () => {
		try {
			await updateTourStatus({
				tour_status: (user?.tourStatus || 1) + 1,
			});
			dispatch(updateTourStatusAuth((user?.tourStatus || 1) + 1));
		} catch (error) {
			console.log(error);
		} finally {
			console.log("finished");
		}
	};

	return (
		<>
			<Box className="filter-section-parent">
				{isTablet && <span className="map-filter-btn" onClick={handleFilterHeight}>
					<FilterOutlined />
				</span>}
				<FilterForm filterHeight={filterHeight} />
			</Box>
			<Box className="map-marker-section">
				<Row>
					<Col
						xs={24}
						md={mapSectionCol}
						style={{ transition: "0.6s" }}
					>
						<Box className="world-map">
							<GoogleMap
								drawerOpen={drawerOpen}
								setDrawerOpen={setDrawerOpen}
								setLat={setLat}
								setLng={setLng}
								zoom={zoom}
							/>
						</Box>
					</Col>
					<Col xs={contentSectionCol} style={{ transition: "0.4s" }}>
						<Box className="world-map-sidebar">
							<FlexBox
								flexDirection="row"
								gap={24}
								className="user-details-box"
							>
								<Avatar
									className="user-details-box-avatar"
									size={{
										xs: 54,
										sm: 54,
										md: 54,
										lg: 54,
										xl: 64,
										xxl: 64,
									}}
									// icon={<AntDesignOutlined />}
								/>
								<FlexBox gap={4}>
									<Paragraph className="color-black f-bold">
										{user?.fullname}
									</Paragraph>
									<Paragraph fontSize={10}>
										Total trips - {user?.totalTrips || 0}
									</Paragraph>
								</FlexBox>
							</FlexBox>
							<Divider />
							<Box className="marker-tabs-box">{children}</Box>
						</Box>
					</Col>
					{!isNewUser && (
						<Box
							ref={ref1}
							className="side-drawer-toggle-btn"
							onClick={handleToggleDrawer}
							style={{
								...isCollapseBtnStyle,
								transition: "0.4s",
							}}
						>
							{isCollapse ? (
								isMobile || isTablet ? (
									<ArrowUpOutlined />
								) : (
									<ArrowRightOutlined />
								)
							) : isMobile || isTablet ? (
								<ArrowDownOutlined />
							) : (
								<ArrowLeftOutlined />
							)}
						</Box>
					)}
				</Row>
			</Box>

			<CreateScenerDrawer
				drawerOpen={drawerOpen}
				setDrawerOpen={setDrawerOpen}
				lat={lat}
				lng={lng}
				handleMarkerSubmit={handleMarkerSubmit}
			/>
			{isNewUser === 1 && !isHideWelcomePopup && <NewUserModal />}

			<Tour open={open} onClose={handleClose} steps={steps} />
		</>
	);
};

export default MapPageLayout;
