import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

// reducx persist
import storage from "redux-persist/lib/storage";
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from "redux-persist";

import authReducer from "./slices/authSlice";
import mapReducer from "./slices/mapSlice";

const authConfig = {
  key: "auth",
  storage,
  whitelist: ["user", "token", "hideWelcomePopup"],
  blacklist: ["isLoading","loginError", "errorText"],
}

const mapConfig = {
  key: "map",
  storage,
  whitelist: ["isCollapse"],
  blacklist: ["reRender", "sceneData"],
}

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["auth", "map"],
};

const reducers = combineReducers({
  auth: persistReducer(authConfig, authReducer),
  map: persistReducer(mapConfig, mapReducer)
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
      devTools: true
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
