import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';

import "components/modals/style.scss";
import { Box, Paragraph, Title } from 'components/StyledComponents';
import { UseAppDispatch } from 'hooks/hooks';
import { updateWelcomeKey } from 'store/slices/authSlice';

const NewUserModal = () => {

    const dispatch = UseAppDispatch();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
    
    const handleCancel = () => {
        setIsModalOpen(false);
        dispatch(updateWelcomeKey());
    };

    return (
        <Modal
            className="new-user-modal"
            // title="Basic Modal"
            centered
            open={isModalOpen}
            onCancel={handleCancel}
            footer={false}
            closeIcon={false}
        >
            <Box className='new-user-modal-body'>
                <Box className='new-user-modal-body-content'>
                    <Title level={5} className="site-color">Welcome to Scenes</Title>
                    <Paragraph className='new-user-modal-body-content-paragraph' fontSize={14}>Plan, track and relive your trips in a smart and beautiful way. Try clicking on map to create a new trip.</Paragraph>
                </Box>
                <Button type="primary" className="primary" onClick={() => {
                    setIsModalOpen(false);
                    dispatch(updateWelcomeKey());
                }} >Start</Button>
            </Box>
        </Modal>
    )
}

export default NewUserModal