import { notification } from "antd";

const SUCCESS = (message: string, description?: string) => notification.success({
	message: message,
	description: description
});

const ERROR = (message: string, description?: string) => notification.error({
	message: message,
	description: description
});

export const notifications ={
	SUCCESS: SUCCESS,
	ERROR: ERROR
}