import React, { useState } from "react";
import { Row, Col, Form, Input, Button } from "antd";

import {
	Box,
	Container,
	FlexBox,
	Paragraph,
	Title,
} from "components/StyledComponents";

import { resetPassword } from "../../services/auth";
import { IResetPasswordForm, IResetPasswordRequest } from "../../types";

import { notifications } from "components/notifications";
import { DEFAULT_ERROR_MSG } from "constants/Common";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import "pages/auth/style.scss";

const ResetPassword: React.FC = () => {

    const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    if(!searchParams.get('reset_token') || !searchParams.get('email')){
        notifications.ERROR(
            "ERROR",
            "Something went wrong, please try again"
        );
        return <Navigate to="/login" />;
    }
    

	const onFinish = (values: IResetPasswordForm) => {
        const data: IResetPasswordRequest = {
            email: searchParams.get('email') as string,
            reset_token: searchParams.get('reset_token') as string,
            password: values.password
        };
		updatePassword(data);
	};

	// API
	const updatePassword = async (userData: IResetPasswordRequest) => {
		setIsLoading(true);
		try {
			const response: any = await resetPassword(userData);
			notifications.SUCCESS("SUCCESS", response.message);
            navigate("/login")
		} catch (error: any) {
			notifications.ERROR(
				"ERROR",
				error.response.data.errors ||
					error.response.data.message ||
					DEFAULT_ERROR_MSG
			);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Box className="auth-page">
				<Box className="auth-section-image">
					{/* <img src={LOGIN_BG} alt="Login" /> */}
				</Box>
				<Container>
					<Box className="auth-section">
						<Row gutter={{ lg: 120 }}>
							<Col lg={12}></Col>
							<Col lg={12}>
								<Box
									className="auth-section-form"
									style={{ padding: "120px 0", width: "80%" }}
								>
									<Title level={2}>Reset Password</Title>
									<Paragraph>
										Enter a new password for {searchParams.get('email')}
									</Paragraph>
									<Form
										name="basic"
										layout="vertical"
										initialValues={{
											password: "",
										}}
										onFinish={onFinish}
										autoComplete="off"
									>
										<FlexBox
											className="auth-section-form-inputs"
											// gap={20}
										>
											<Form.Item
												label="Password"
												name="password"
												rules={[
													{
														required: true,
														message:
															"Please input your password!",
													},
												]}
											>
												<Input.Password />
											</Form.Item>

                                            <Form.Item
												label="Confirm Password"
												name="confirm"
												rules={[
													{
														required: true,
														message:
															"Please input your password!",
													},
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                          if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                          }
                                                          return Promise.reject(new Error('The new password that you entered do not match!'));
                                                        },
                                                    }),
												]}
											>
												<Input.Password />
											</Form.Item>

                                            
										</FlexBox>

										<Form.Item
											style={{
												marginBottom: 0,
												textAlign: "center",
											}}
										>
											<Button
												type="primary"
												htmlType="submit"
												block
												className="round-btn"
												loading={isLoading}
												disabled={isLoading}
											>
												Send
											</Button>
										</Form.Item>
									</Form>
								</Box>
							</Col>
						</Row>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default ResetPassword;
