import React, { useState, useEffect } from "react";
import { Breadcrumb, Button, Empty } from "antd";
import { motion, Variants } from "framer-motion";
import {
	DeleteOutlined
  } from '@ant-design/icons';

import { Box, FlexBox, Paragraph, Title } from "components/StyledComponents";

// import TRIP_IMG from "assets/images/map/trip.png";
import { allMarkers, deleteTrip, getMarkers, getTripList, updateTrip } from "services/map";
import moment from "moment";
import TripSkeleton from "components/skeleton/TripSkeleton";
import { UseAppDispatch, UseAppSelector } from "hooks/hooks";
import { addScenesData } from "store/slices/mapSlice";
import EditTripDrawer from "components/drawers/EditTripDrawer";
import { ITrip } from "types";
import { notifications } from "components/notifications";
import { DEFAULT_ERROR_MSG } from "constants/index";
import ConfirmModal from "components/modals/ConfirmModal";

interface IBreadCrumb {
	title: string;
	key: string;
}

interface IProps {
	handleTripClick: (tripId: string) => void;
	ref: React.MutableRefObject<null>;
}

const cardVariants: Variants = {
	offscreen: {
		y: 20
	},
	onscreen: {
		y: 0,
		rotate: 0,
		transition: {
			type: "spring",
			bounce: 0.4,
			duration: 0.8
		}
	}
};

const variants = {
	open: {
		y: 0,
		opacity: 1,
		transition: {
			y: { stiffness: 1000, velocity: -100 }
		}
	},
	closed: {
		y: 50,
		opacity: 0,
		transition: {
			y: { stiffness: 1000 }
		}
	}
};

const Trips = ({ handleTripClick, ref }: IProps) => {
	const brdeacrumbItems: IBreadCrumb[] = [
		// { title: 'Trips', key: "trips" },
		// { title: 'Trips', key: "trips" },
	];

	const dispatch = UseAppDispatch();

	const isNewUser = UseAppSelector(state => state.auth.user?.isNewUser);
	const reRender = UseAppSelector(state => state.map.reRender);

	const [allUserTrips, setAllUserTrips] = useState<Array<ITrip>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	// Edit Drawer
	const [activeTrip, setActiveTrip] = useState<ITrip | null>(null);
	const [activeDeleteTrip, setActiveDeleteTrip] = useState<ITrip | null>(null);
	const [visible, setVisible] = useState<boolean>(false);
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

	useEffect(() => {
		isNewUser === 0 && fetchTrips();
		fetchMarkers();
	}, [isNewUser, reRender]);

	const handleEdit = (tripData: ITrip) => {
		setDrawerOpen(true);
		setActiveTrip(tripData);
	}

	const handleDeleteBtn = (trip: ITrip) => {
		setActiveDeleteTrip(trip);
		setVisible(true);
	}

	// Element

	const tripSingleElement = allUserTrips.length > 0 ? allUserTrips.map((trip: ITrip, index: number) => (
		<motion.div
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			// initial={'offscreen'}
			// whileInView={'onscreen'}
			viewport={{ once: true, amount: 0.2 }}
			// variants={{ onscreen: { transition: { duration: 10 } } }}
			variants={variants}
			whileHover={{ y: -3 }}
			whileTap={{ scale: 0.97 }}
			key={index}
			onClick={() => handleTripClick(trip.id)}
			className="trip-box-single"
			style={{ backgroundImage: `url(${trip?.fallback_image ? trip?.fallback_image : trip.image})` }}
			ref={index === 0 ? ref : null}
		>
			<Button type="primary" className="sm-btn trip-box-single-edit-btn" onClick={(event) => {
				handleEdit(trip);
				event.stopPropagation();
			}}>
				EDIT
			</Button>
			<Button type="primary" className="sm-btn trip-box-single-delete-btn" onClick={(event) => {
				handleDeleteBtn(trip);
				event.stopPropagation();
			}}>
				<DeleteOutlined />
			</Button>
			<Title level={4} className="trip-box-single-title">
				{trip.name}
			</Title>
			<Box className="trip-box-single-right-box">
				<Paragraph>{ trip.city }</Paragraph>
				<Paragraph>{moment(trip.latest_scene_date).format("MM/DD/YYYY")}</Paragraph>
			</Box>
		</motion.div>
	)) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No data found. Please add trip to see data."} />;

	// API CALL
	const fetchTrips = async () => {
		setIsLoading(true);
		try {
			const response: any = await getTripList();
			setAllUserTrips(response.data ? response.data : []);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const updateTripData = async (tripId: string, formData: any) => {
		setIsLoading(true);
		try {
			const response: any = await updateTrip(tripId, formData);
			response.data && setAllUserTrips(response.data);
			notifications.SUCCESS("SUCCESS", "Trip updated successfully");
			fetchTrips();
			setDrawerOpen(false);
		} catch (error: any) {
			notifications.ERROR("ERROR", error.response.data.message || DEFAULT_ERROR_MSG);
			// console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	const fetchMarkers = async () => {
		try {
			const response = await getMarkers();
			dispatch(addScenesData(response.data || []));
		} catch (error: any) {
			notifications.ERROR("ERROR", error.response.data.message || DEFAULT_ERROR_MSG);
		}
	}

	const removeTrip = async () => {
		try {
			await deleteTrip(activeDeleteTrip?.id || "");
			notifications.SUCCESS("SUCCESS", "Trip deleted successfully");
			fetchMarkers();
			fetchTrips();
		} catch(error: any) {
			console.log("error >>", error);
		} finally{
			console.log("done");
		}
	}

	return (
		<>
			<Box>
				<Breadcrumb
					className="tabs-breadcrumb"
					separator=">"
					items={brdeacrumbItems}
				/>
				<FlexBox
					flexDirection={"row"}
					justifyContent={"space-between"}
					style={{ marginTop: 6 }}
				>
					<Paragraph fontSize={14} className="site-color f-bold">
						Trips
					</Paragraph>
					{/* <Button
						type="primary"
						className="sm-btn icon outline"
						icon={<PlusOutlined />}
						ghost
					>
						ADD TRIP
					</Button> */}
				</FlexBox>
				<FlexBox className="trip-box" style={{ overflow: 'hidden' }}>
					{isLoading ? <TripSkeleton /> : tripSingleElement}
				</FlexBox>
			</Box>
			<EditTripDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} activeTrip={activeTrip} updateTripData={updateTripData} />
			<ConfirmModal
				isVisible={visible}
				setIsVisible={setVisible}
				callbackFunction={removeTrip}
				description="delete this trip, all the scenes belong to this trip will be deleted too"
			/>
		</>
	);
};

export default Trips;
