import React, { useState } from "react";
import { Row, Col, Form, Input, Button } from "antd";
import { Link } from "react-router-dom";
import { UseAppDispatch } from "../../hooks/hooks";

import {
	Box,
	Container,
	FlexBox,
	Paragraph,
	Title,
} from "components/StyledComponents";

import { AxiosResponse } from "axios";
import { loginUser } from "../../services/auth";
import { ILoginForm } from "../../types";

import { addAuthData } from "store/slices/authSlice";
import SocialLogin from "./SocialLogin";

import { notifications } from "components/notifications";
import { DEFAULT_ERROR_MSG } from "constants/index";

import "pages/auth/style.scss";

const Login: React.FC = () => {

	const dispatch = UseAppDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onFinish = (values: ILoginForm) => {
        authenticationUser(values);
    };

    // API

    const authenticationUser = async (userData: ILoginForm) => {
        setIsLoading(true);
        try {
            const response: AxiosResponse<any> = await loginUser(userData);
            const authData = {
                user: {
                    fullname: response.data.fullname,
                    user_email: response.data.user_email,
                    isNewUser: response.data.isNewUser,
                    totalTrips: response.data.totalTrips,
                    tourStatus: response.data.tourStatus,
                },
                token: response.data.token,
            };
            dispatch(addAuthData(authData));
        } catch (error: any) {
			// console.log("error.response.data.errors >>", error.response.data.errors);
			notifications.ERROR("ERROR", error.response.data.errors || error.response.data.message || DEFAULT_ERROR_MSG);
        } finally {
            setIsLoading(false);
        }
    };

	return (
		<>
			<Box className="auth-page">
                <Box className="auth-section-image">
                    {/* <img src={LOGIN_BG} alt="Login" /> */}
                </Box>
				<Container>
					<Box className="auth-section">
						<Row gutter={{ lg: 120 }}>
							<Col lg={12}>
								
							</Col>
							<Col lg={12}>
								<Box className="auth-section-form">
									<Title level={2}>Login</Title>
									<Form
										name="basic"
										layout="vertical"
										initialValues={{
											email: "",
											password: "",
										}}
										onFinish={onFinish}
										autoComplete="off"
									>
										<FlexBox
											className="auth-section-form-inputs"
											// gap={20}
										>
											<Form.Item
												name="email"
												rules={[
													{
														required: true,
														message:
															"Please input your email!",
													},
													{
														type: "email",
														message:
															"Invalid email format",
													},
												]}
											>
												<Input placeholder="Email" />
											</Form.Item>

											<Form.Item
												name="password"
												rules={[
													{
														required: true,
														message:
															"Please input your password!",
													},
												]}
											>
												<Input.Password placeholder="Password" />
											</Form.Item>
										</FlexBox>

										<Form.Item className="auth-section-form-forgot-password">
											<Link to="/forgot-password">Forgot Password?</Link>
										</Form.Item>

										<Form.Item
											style={{
												marginBottom: 0,
												textAlign: "center",
											}}
										>
											<Button
												type="primary"
												htmlType="submit"
												block
												className="round-btn"
												loading={isLoading}
												disabled={isLoading}
											>
												Login
											</Button>
										</Form.Item>
									</Form>
									<Paragraph fontSize={14} textAlign={"center"}>or</Paragraph>
									<Link to="/signup" style={{ textAlign: "center" }}>Sign Up</Link>
									<SocialLogin setIsLoading={setIsLoading} />
								</Box>
							</Col>
						</Row>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default Login;
