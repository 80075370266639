export const COLOR_CODES = [
	"#000000", // Black
	"#333333", // Dark Gray
	"#555555", // Dim Gray
	"#666666", // Cool Gray
	"#777777", // Light Slate Gray
	"#888888", // Gray
	"#999999", // Light Gray
	"#111111", // Jet Black
	"#222222", // Very Dark Gray
	"#444444", // Charcoal
	"#666666", // Dark Slate Gray
	"#777777", // Slate Gray
	"#888888", // Light Slate Gray
	"#990000", // Crimson
	"#AA0000", // Firebrick
	"#BB0000", // Dark Red
	"#CC0000", // Red
	"#DD0000", // Scarlet
	"#990099", // Dark Magenta
	"#AA00AA", // Purple
	"#BB00BB", // Dark Purple
	"#CC00CC", // Medium Purple
	"#DD00DD", // Orchid
	"#006600", // Dark Green
	"#007700", // Pine Green
	"#008800", // Green
	"#009900", // Dark Lime Green
	"#00AA00", // Lime Green
	"#00BB00", // Bright Green
	"#006666", // Teal
	"#007777", // Dark Cyan
	"#008888", // Cyan
	"#009999", // Light Cyan
	"#0066CC", // Navy Blue
	"#0077DD", // Dark Blue
	"#0088EE", // Blue
	"#0099FF", // Light Blue
	"#663300", // Brown
	"#774400", // Dark Orange
	"#885500", // Orange
	"#996600", // Dark Yellow
	"#AA7700", // Yellow
	"#BB8800", // Gold
	"#FF9900", // Amber
	"#FFAA00", // Orange-Yellow
	"#FFBB00", // Dark Yellow
	"#FFCC00", // Yellow
];
export const MARKER_TYPES = [
	{ value: "1", label: "Entertainment" },
	{ value: "2", label: "Education" },
	{ value: "3", label: "Nightlife" },
	{ value: "4", label: "Shopping" },
	{ value: "5", label: "Dining" },
	{ value: "6", label: "Outdoors" },
];
