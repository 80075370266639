import React from 'react';
import { BrowserRouter } from "react-router-dom";
import Routing from "./Routing";

import "./globleStyle.scss";

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </>
  );
};

export default App;
