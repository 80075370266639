import React, { useState } from "react";
import { Modal, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface"
;
import { notifications } from "components/notifications";

interface IProps {
	title: string;
	uploadFunction: (fileData: UploadFile[]) => Promise<void>;
	initialImage?: string;
}

const getBase64 = (file: RcFile): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});

const MultiImages = ({ title, uploadFunction, initialImage = "" }: IProps) => {
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [isError, setIsError] = useState<boolean>(false);

	const beforeUpload = (file: RcFile) => {
		const isJpgOrPng =
			file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp";
		if (!isJpgOrPng) {
			setIsError(true);
			notifications.ERROR("You can only upload JPG/PNG file!");
		}
		return isJpgOrPng;
	};

	const handleCancel = () => setPreviewOpen(false);

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile);
		}

		setPreviewImage(file.url || (file.preview as string));
		setPreviewOpen(true);
		setPreviewTitle(
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
		);
	};

	const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
		if(!isError){
			setFileList(newFileList);

			const originalObjects = fileList.map((file : UploadFile) => file.originFileObj as UploadFile<Blob>);
			originalObjects && uploadFunction(originalObjects);
		}
		isError && setIsError(false);
	}

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	return (
		<>
			<Upload
				action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
				listType="picture-card"
				fileList={fileList}
				onPreview={handlePreview}
				onChange={handleChange}
				beforeUpload={beforeUpload}
				className="multiple-file-uploader"
			>
				{uploadButton}
			</Upload>
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				<img
					alt="example"
					style={{ width: "100%" }}
					src={previewImage}
				/>
			</Modal>
		</>
	);
};

export default MultiImages;
