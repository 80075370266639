import React, { useEffect, useState } from "react";
import { Drawer, Button, Form, Input, Select, DatePicker, UploadFile } from "antd";
import dayjs from "dayjs";

import SingleImage from "components/upload/SingleImage";
import { ISceneDetail } from "types";
import { MARKER_TYPES } from "constants/Map";

import "components/drawers/style.scss";
import MultiImages from "components/upload/MultiImages";

const { TextArea } = Input;

interface IProps {
	drawerOpen: boolean;
	setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    sceneDetail: ISceneDetail | null;
    updateSceneDetail: (sceneId: string, formData: FormData) => Promise<void>;
}

interface IMarkerForm {
	marker_name: string;
	description: string;
	type: string;
	date: string;
}

const getInitialValues = (tripData: ISceneDetail | null) => {
    if(!tripData){
        return {};
    }
    return {
        marker_name: tripData?.marker_name,
        description: tripData?.description,
		type: tripData?.type,
		date: dayjs(tripData?.visit_date, 'YYYYMM/DD')
    }
}

const EditSceneDrawer = ({ drawerOpen, setDrawerOpen, sceneDetail, updateSceneDetail }: IProps) => {
	const [form] = Form.useForm();

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [galleryImage, setGalleryImage] = useState<UploadFile[]>();

    useEffect(() => {
		if(drawerOpen){
			form.resetFields();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [drawerOpen])

    const initialValues = getInitialValues(sceneDetail);

	const handleGalleryImage = async (fileData: UploadFile[]) => {
		setGalleryImage(fileData);
	};

	const onFinish = async (values: IMarkerForm) => {

		const formData = new FormData();
		formData.append("marker_name", values.marker_name);
		formData.append("description", values.description);
		formData.append("type", values.type);
		formData.append("date", values.date);
		galleryImage && galleryImage.forEach((image: UploadFile<Blob>, index: number) => {
			formData.append("gallery[]", image as any)
		});

        if(sceneDetail?.id){
            setIsSubmitting(true);
            await updateSceneDetail(`${sceneDetail?.id}`, formData);
            setIsSubmitting(false);
        }
	};

	return (
		<Drawer
			destroyOnClose
			title={"Edit Your Trip"}
			placement="right"
			onClose={() => setDrawerOpen(false)}
			open={drawerOpen}
			rootClassName="create-scene-drawer-root"
			className="create-scene-drawer"
		>
			<Form
				form={form}
				name="basic"
				style={{ maxWidth: 600 }}
				layout="vertical"
				initialValues={initialValues}
				onFinish={onFinish}
				autoComplete="off"
			>

				<Form.Item
					label="Marker Name"
					name="marker_name"
					rules={[
						{
							required: true,
							message: "Please input your Marker Name!",
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Marker Description"
					name="description"
					rules={[
						{
							required: true,
							message: "Please input your description!",
						},
					]}
				>
					<TextArea rows={4} />
				</Form.Item>

				<Form.Item
					label="Category"
					name="type"
					rules={[
						{
							required: true,
							message: "Please input your type!",
						},
					]}
				>
					<Select
						style={{ width: "100%" }}
						placeholder="Choose trip"
						options={MARKER_TYPES}
					/>
				</Form.Item>

				<Form.Item
					label="Date"
					name="date"
					rules={[
						{
							required: true,
							message: "Please input your date!",
						},
					]}
				>
					<DatePicker style={{ width: "100%" }} />
				</Form.Item>

				<Form.Item
                    label="Gallery"
                >
                    <MultiImages title={"Upload Profile Picture"} uploadFunction={handleGalleryImage} />
                </Form.Item>

				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						className="btn-primary"
						loading={isSubmitting}
						disabled={isSubmitting}
					>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</Drawer>
	);
};

export default EditSceneDrawer;
