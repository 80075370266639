import { Box, Paragraph } from "components/StyledComponents";
import moment from "moment";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IScene } from "types";

import { motion, Variants } from "framer-motion";

import TRIP_IMG from "assets/images/map/trip.png";

import "components/common/style.scss";

interface IProps {
	scene: IScene;
	handleSceneClick: (sceneId: string) => void;
}

const cardVariants: Variants = {
	offscreen: {
		y: 20,
	},
	onscreen: {
		y: 0,
		rotate: 0,
		transition: {
			type: "spring",
			bounce: 0.4,
			duration: 0.8,
		},
	},
};

const SceneElement = ({ scene, handleSceneClick }: IProps) => {
	// const navigate = useNavigate();

	// const [searchParams, setSearchParams] = useSearchParams();

	// const handleSceneClick = (sceneId: number) => {
	// searchParams.delete("trip_id");
	// searchParams.set("scene_id", String(sceneId));
	// console.log("Am I here?");

	// navigate(`/mark-location/scene-detail/${sceneId}`);
	// };

	const sceneBgImage = scene.images && scene.images[scene.images.length - 1];

	return (
		<motion.div
			className="scene-box-single"
			onClick={() => handleSceneClick(String(scene.id))}
			initial={"offscreen"}
			whileInView={"onscreen"}
			viewport={{ once: true }}
			variants={cardVariants}
			whileHover={{ scale: 1.02 }}
			transition={{ type: "spring", stiffness: 400, damping: 20 }}
		>
			<Paragraph>{scene.marker_name}</Paragraph>
			<Box
				className="scene-box-single-details"
				style={{
					background: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${
						sceneBgImage || TRIP_IMG
					})`,
				}}
			>
				<Box className="scene-box-single-details-footer">
					<Paragraph className="scene-box-single-details-footer-total-images">
						{scene?.images ? scene?.images?.length : "0"}
					</Paragraph>
					<Box className="scene-box-single-details-footer-text">
						<Paragraph>{scene.city}</Paragraph>
						<Paragraph>
							{moment(scene.visit_date).format("MM/DD/YYYY")}
						</Paragraph>
					</Box>
				</Box>
			</Box>
		</motion.div>
	);
};

export default SceneElement;
