import React, { useEffect, useState } from "react";
import { Drawer, Button, Form, Input } from "antd";

import SingleImage from "components/upload/SingleImage";
import { ITrip } from "types";

import "components/drawers/style.scss";

const { TextArea } = Input;

interface IProps {
	drawerOpen: boolean;
	setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    activeTrip: ITrip | null;
    updateTripData: (tripId: string, formData: FormData) => Promise<void>;
}

interface IMarkerForm {
	trip: string;
	name: string;
	description: string;
}

const getInitialValues = (tripData: ITrip | null) => {
    if(!tripData){
        return {};
    }
    return {
        name: tripData?.name,
        description: tripData?.description,
    }
}

const EditTripDrawer = ({ drawerOpen, setDrawerOpen, activeTrip, updateTripData }: IProps) => {
	const [form] = Form.useForm();

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [tripImage, setTripImage] = useState<Blob>();
	const [resetImage] = useState<boolean>(false);

    useEffect(() => {
		if(drawerOpen){
			form.resetFields();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [drawerOpen])

    const initialValues = getInitialValues(activeTrip);

	const onFinish = async (values: IMarkerForm) => {

		const formData = new FormData();
		formData.append("name", values.name);
		formData.append("description", values.description);
		tripImage && formData.append("image", tripImage);

        if(activeTrip?.id){
            setIsSubmitting(true);
            await updateTripData(activeTrip?.id, formData);
            setIsSubmitting(false);
        }
	};

	const handleTripImage = async (fileData: Blob) => {
		setTripImage(fileData);
	};

	return (
		<Drawer
			destroyOnClose
			title={"Edit Your Trip"}
			placement="right"
			onClose={() => setDrawerOpen(false)}
			open={drawerOpen}
			rootClassName="create-scene-drawer-root"
			className="create-scene-drawer"
		>
			<Form
				form={form}
				name="basic"
				style={{ maxWidth: 600 }}
				layout="vertical"
				initialValues={initialValues}
				onFinish={onFinish}
				autoComplete="off"
			>

				<Form.Item
					label="Trip Name"
					name="name"
					rules={[
						{
							required: true,
							message: "Please input your Trip Name!",
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Trip Description"
					name="description"
					rules={[
						{
							required: true,
							message: "Please input your description!",
						},
					]}
				>
					<TextArea rows={4} />
				</Form.Item>

				<Form.Item
					label="Trip Image"
					name="image"
				>
					<SingleImage
						title={"Upload Trip Image"}
						uploadFunction={handleTripImage}
						resetImage={resetImage}
					/>
				</Form.Item>

				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						className="btn-primary"
						loading={isSubmitting}
						disabled={isSubmitting}
					>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</Drawer>
	);
};

export default EditTripDrawer;
