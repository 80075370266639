import React from "react";
import { Skeleton } from "antd";

import "components/skeleton/style.scss";

const ScenesSkeleton: React.FC = () => {
	return (
		<>
			{[1, 2].map((num: number, index: number) => (
				<Skeleton
					key={num}
					className="scene-skeleton"
					active
					avatar={{ shape: "square" }}
				/>
			))}
		</>
	);
};

export default ScenesSkeleton;
