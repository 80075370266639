import React, { useEffect, useState } from "react";
import { Breadcrumb, Empty } from "antd";
import { useNavigate, Outlet, useSearchParams } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

import { Box, FlexBox, Paragraph, Title } from "components/StyledComponents";
import { Link } from "react-router-dom";

import { getNoTripTourList, getTourList } from "services/map";
import moment from "moment";
import ScenesSkeleton from "components/skeleton/ScenesSkeleton";
import { UseAppDispatch, UseAppSelector } from "hooks/hooks";
import { addScenesData } from "store/slices/mapSlice";
import { IScene } from "types";
import { notifications } from "components/notifications";
import { DEFAULT_ERROR_MSG } from "constants/index";
import SceneElement from "components/common/SceneElement";
import EmptyData from "components/common/EmptyData";

interface IBreadCrumb {
	title: string | JSX.Element;
	key: string;
}

interface IProps {
	handleSceneBreacrumbClick?: () => void;
	tripId?: string;
	handleSceneClick: (sceneId: string) => void;
}

const Scenes = ({ handleSceneBreacrumbClick, tripId, handleSceneClick}: IProps) => {

	const navigate = useNavigate();
	const dispatch = UseAppDispatch();

	const reRender = UseAppSelector((state: any) => state.map.reRender);

	const brdeacrumbItems: IBreadCrumb[] = [
		{
			title: (
				<span
					className="link"
					onClick={
						handleSceneBreacrumbClick && handleSceneBreacrumbClick
					}
				>
					Trips
				</span>
			),
			key: "trips",
		},
		{ title: "Scenes", key: "scenes" },
	];

	const [allScenes, setAllScenes] = useState<Array<IScene>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// const handleSceneClick = (sceneId: number) => {
	// 	navigate(`/mark-location/scene-detail/${sceneId}`);
	// };

	useEffect(() => {
		tripId ? fetchScenes(tripId) : fetchScenesWithNoTrip();
	}, [reRender, tripId]);

	const sceneElement = allScenes.map((scene: IScene, index: number) => (
		<SceneElement scene={scene} key={index} handleSceneClick={handleSceneClick} />
	));

	// API CALL

	const fetchScenes = async (tripId: string) => {
		setIsLoading(true);
		try {
			const scene = await getTourList(tripId);
			setAllScenes(scene.data);
			dispatch(addScenesData(scene.data));
		} catch (error: any) {
			notifications.ERROR("ERROR", error.response.data.message || DEFAULT_ERROR_MSG);
			handleSceneBreacrumbClick && handleSceneBreacrumbClick();
		} finally {
			setIsLoading(false);
		}
	};

	const fetchScenesWithNoTrip = async () => {
		setIsLoading(true);
		try {
			const scene = await getNoTripTourList();
			setAllScenes(scene.data);
			dispatch(addScenesData(scene.data));
		} catch (error) {
			dispatch(addScenesData([]));
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<>
			<Box>
				{tripId && (
					<Breadcrumb
						className="tabs-breadcrumb"
						separator=">"
						items={brdeacrumbItems}
					/>
				)}
				<FlexBox
					flexDirection={"row"}
					justifyContent={"space-between"}
					style={{ marginTop: 6 }}
				>
					<Paragraph fontSize={14} className="site-color f-bold">
						{tripId ? "Scenes" : "Scenes with no trip"}
					</Paragraph>
					{/* <Button type="primary" className="sm-btn icon outline" icon={<PlusOutlined />} ghost>ADD TRIP</Button> */}
				</FlexBox>
				<FlexBox className="scene-box">
					{isLoading ? <ScenesSkeleton /> : sceneElement}
				</FlexBox>
				{(!isLoading && allScenes.length === 0) && <EmptyData message="No data found. Please add scenes to see data." />}
			</Box>
		</>
	);
};

export default Scenes;
