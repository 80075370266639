import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button } from "antd";

import {
	Box,
	Container,
	FlexBox,
	Title,
} from "components/StyledComponents";

import { UseAppDispatch } from "../../hooks/hooks";
import { forgetPassword } from "../../services/auth";
import { IForgotPasswordForm } from "../../types";

import { notifications } from "components/notifications";
import { DEFAULT_ERROR_MSG } from "constants/Common";
import "pages/auth/style.scss";

const ForgotPassword: React.FC = () => {

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isResendActive, setIsResendActive] = useState<boolean | null>(null);
	const [seconds, setSeconds] = useState<number | null>(null);

	useEffect(() => {
		const timerInterval = setInterval(() => {
			seconds && setSeconds((prevSeconds) => prevSeconds && prevSeconds - 1);
		}, 1000);

		return () => clearInterval(timerInterval);
	}, [isResendActive]);

	useEffect(() => {
		if (seconds === 0) {
			// Timer has reached 0 seconds, you can handle the action here
			console.log("Timer Ended!");
		}
	}, [seconds]);

	const onFinish = (values: IForgotPasswordForm) => {
		sendEmail(values);
	};

	// API
	const sendEmail = async (userData: IForgotPasswordForm) => {
		setIsLoading(true);
		try {
			const response: any = await forgetPassword(userData);
			notifications.SUCCESS("SUCCESS", response.message);
			setIsResendActive(isResendActive === null ? true : false);
			setSeconds(60);
		} catch (error: any) {
			notifications.ERROR(
				"ERROR",
				error.response.data.errors ||
					error.response.data.message ||
					DEFAULT_ERROR_MSG
			);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Box className="auth-page">
				<Box className="auth-section-image">
					{/* <img src={LOGIN_BG} alt="Login" /> */}
				</Box>
				<Container>
					<Box className="auth-section">
						<Row gutter={{ lg: 120 }}>
							<Col lg={12}></Col>
							<Col lg={12}>
								<Box
									className="auth-section-form"
									style={{ padding: "120px 0", width: "80%" }}
								>
									<Title level={2}>Forgot Password</Title>
									<Form
										name="basic"
										layout="vertical"
										initialValues={{
											email: "",
										}}
										onFinish={onFinish}
										autoComplete="off"
									>
										<FlexBox
											className="auth-section-form-inputs"
											// gap={20}
										>
											<Form.Item
												label="Email"
												name="email"
												rules={[
													{
														required: true,
														message:
															"Please input your email!",
													},
													{
														type: "email",
														message:
															"Please enter valid email!",
													},
												]}
											>
												<Input />
											</Form.Item>
										</FlexBox>

										{seconds !== null && (
											<Form.Item className="auth-section-form-forgot-password">
												<Box
													style={{
														cursor: "pointer",
													}}
												>
													Didn&apos;t receive? Send
													again {seconds ? `in ${seconds}s` : ''}
												</Box>
											</Form.Item>
										)}

										<Form.Item
											style={{
												marginBottom: 0,
												textAlign: "center",
											}}
										>
											<Button
												type="primary"
												htmlType="submit"
												block
												className="round-btn"
												loading={isLoading}
												disabled={isLoading || seconds as number > 0}
											>
												Send
											</Button>
										</Form.Item>
									</Form>
								</Box>
							</Col>
						</Row>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default ForgotPassword;
